import { createStore } from "vuex";

export default createStore({
  state: {
    user: null,
    isLoggedIn: false,
    token: null,
    loggingOut: false,
    admin_workspaces: [],
    selected_admin_wrokspace: null,
    sidebar_routes: [],
    cartArray: [],
    cardId: '',
    selectedPage:'home',
    currency:{},
    country:{}
  },
  getters: {
    isAuthenticated(state) {
      return state.isLoggedIn
    },
    getUserDetails(state) {
      return state.user
    },
    getSidebarRoutes(state) {
      return state.sidebar_routes
    },
    getCardId(state) {
      console.log(state.cardId)
      return state.cardId
    },
    getCartArray(state) {
      console.log(state.cartArray)
      return state.cartArray
    },
    getCartArrayLength(state) {
      return state.cartArray.length
    },
    getTotalCartPrice(state) {
      function calculateTotalCost(array: any) {
        let totalCost = 0;
        if (Array.isArray(array)) {
          for (let item of array) {
            totalCost += item.price * item.quantity;
          }
        } else {
          console.error('Input is not an array');
        }
        return totalCost;
      }


      return calculateTotalCost(state.cartArray)
    },
    getSelectedPage(state){
      return state.selectedPage    
    },
    getSelectedCurrency(state){
      return state.currency
    },
    getSelectedCountry(state){
      return state.country
    }
  },
  mutations: {
    SET_USER(state: any, payload) {
      const newUser = { ...payload.user }
      if (
        !payload.pictureUpdate &&
        state.user &&
        state.user.profile_picture &&
        state.user.profile_picture.key
      ) {
        newUser.profile_picture = state.user.profile_picture
      }
      state.user = newUser
      localStorage.setItem('user', JSON.stringify(newUser))
    },
    SET_TOKEN(state, token) {
      state.token = token
      state.isLoggedIn = true
      localStorage.setItem('token', token)
    },
    SET_ROUTES(state, routes) {
      state.sidebar_routes = routes
    },
    UPDATE_SELLER_STATUS(state: any, value) {
      let existingData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '') : {};
      existingData.is_seller = value;
      state.user = existingData;
      localStorage.setItem('user', JSON.stringify(existingData));
    },
    UPDATE_USER_STATUS(state) {
      state.isLoggedIn = false;
    },
    LOGOUT(state) {
      state.user = null
      state.isLoggedIn = false
      state.token = null
      state.cartArray = []
      // teamSidebar.value = {
      //   admin: false,
      //   id: null,
      // }
      // const dev = localStorage.getItem('dev')
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      // if (dev) localStorage.setItem('dev', dev)
    },
    UPDATE_CART(state, value) {
      state.cartArray = value
    },
    UPDATE_ID(state, value) {
      state.cardId = value
    },
    UPDATE_PAGE(state, value){
      state.selectedPage = value
    },
    UPDATE_CURRENCY(state, value){
      state.currency = value
    },
    UPDATE_COUNTRY(state, value){
      state.country = value
    }
  },
  actions: {
    login({ commit }, response) {
      commit('SET_USER', { user: response.user })
      commit('SET_TOKEN', response.token)
    },
    logout({ commit }) {
      commit('LOGOUT')
    },
    autoAuthUser({ commit }) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      commit('UPDATE_CART', 0);
      commit('UPDATE_USER_STATUS');
    },
    updateSeller({ commit }, value) {
      commit('UPDATE_SELLER_STATUS', value)
    },
    updateCart({ commit }, value) {
      commit('UPDATE_CART', value)
    },
    triggerCart({ commit }) {
      function uuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
          /[xy]/g,
          function (c) {
            const r = (Math.random() * 16) | 0,
              v = c == 'x' ? r : (r & 3) | 8
            return v.toString(16)
          }
        )
      }
      commit('UPDATE_ID', uuid())
    },
    updateSelectedPage({ commit },value){
      commit('UPDATE_PAGE', value)
    },
    updateCurrency({ commit },value){
      commit('UPDATE_CURRENCY', value)
    },
    updateCountry({ commit },value){
      commit('UPDATE_COUNTRY', value)
    }
  },
  modules: {},
});
