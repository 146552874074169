<script>
import { object, string,number } from "yup";
import { useField, useForm } from "vee-validate";
import _commonService from "../services/common_service";
import { openLoginModal, closeRegisterModal } from "../state/authState";
export default {
  name: "RegisterView",
  inject: ["notyf"],

  data() {
    return {
      btnDisabled: false,
      isLoading: false,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  created() {},
  setup() {
    const schema = object({
      first_name: string().required("First name  is required."),
      last_name: string().required("Last name is required."),
      email: string()
        .email("Please Enter Valid Email.")
        .required("Email is required."),
      password: string().max(15).min(6).required("Password is required."),
      phone: string().required("Phone is required."),
      // confirm_password:
    });
    const {
      errors,
      values: registrationForm,
      validate,
      resetForm,
    } = useForm({
      validationSchema: schema,
      initialValues: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        phone: "",
      },
    });
    const { value: first_name, errorMessage: firstNameError } =
      useField("first_name");
    const { value: last_name, errorMessage: lastNameError } =
      useField("last_name");
    const { value: email, errorMessage: emailError } = useField("email");
    const { value: phone, errorMessage: phoneError } = useField("phone");
    const { value: password, errorMessage: passwordError } =
      useField("password");


    return {
      errors,
      validate,
      resetForm,
      registrationForm,
      first_name,
      last_name,
      email,
      emailError,
      password,
      phone,
      phoneError,
      passwordError,
      firstNameError,
      lastNameError,
      openLoginModal,
      closeRegisterModal,
    };
  },
  methods: {
    handleRegistration() {
      this.btnDisabled = true;
      this.isLoading = true;
      this.validate().then((validationResult) => {
        if (!validationResult.valid) {
          this.btnDisabled = false;
          this.isLoading = false;
          return;
        }
        const url = "users/register";
        let data = {
          ...this.registrationForm,
          // timezone: this.getTimeZone(),
        };
        // this.activationEmail = this.registrationForm.email
        this.registration(url, data);
      });
    },

    registration(url, data) {
      this.isLoading = this.$loading.show();
      _commonService
        .noTokenPost(url, data)
        .then((response) => {
          if (response.data.status == 1) {
            this.isLoading.hide();
            this.notyf.success(response.data.message);
            this.reset();
            // this.$router.push({ name: "login" });
            this.notyf.success("Please check your email for activation link");
            openLoginModal();
          } else {
            this.isLoading.hide();
            this.notyf.error(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading.hide();
          this.notyf.error(error.message);
        });
    },
    reset() {
      this.resetForm();
    },
  },
};
</script>

<template>
  <main>
    <div
      class="modal-overlay"
      @click="closeRegisterModal"
      :class="{ show: active }"
    ></div>

    <div
      class="modal fade"
      :class="active ? 'show' : ''"
      :style="active ? 'display: block !important;' : ''"
    >
      <div
        class="modal-dialog modal-dialog-centered product__modal"
        role="document"
      >
        <div class="modal-content">
          <div class="product__modal-wrapper p-relative">
            <div class="product__modal-close p-absolute">
              <button
                data-bs-dismiss="modal"
                @click="closeRegisterModal"
              >
                <i class="fal fa-times"></i>
              </button>
            </div>
            <div class="product__modal-inner">
              <!-- login Area Strat-->
              <section class="">
                <div class="">
                  <div class="">
                    <div class="">
                      <div class="basic-login">
                        <h3 class="text-center mb-60">Signup From Here</h3>
                        <form @submit.prevent="handleRegistration">
                          <BaseInput
                            label="First Name"
                            type="text"
                            v-model="first_name"
                            :error="firstNameError"
                            :required="true"
                          />
                          <BaseInput
                            label="Last Name"
                            type="text"
                            v-model="last_name"
                            :error="lastNameError"
                            :required="true"
                          />
                          <BaseInput
                            label="Email"
                            type="email"
                            v-model="email"
                            :error="emailError"
                            :required="true"
                          />
                         
                          <BaseInput
                            label="Phone Number"
                            type="text"
                            v-model="phone"
                            :error="phoneError"
                            :required="true"
                          />
                          <BaseInput
                            label="Password"
                            type="password"
                            v-model="password"
                            :error="passwordError"
                            :required="true"
                          />
                          <div class="mt-10"></div>
                          <button
                            type="submit"
                            class="t-y-btn w-100"
                          >
                            Register Now
                          </button>
                          <div class="or-divide"><span>or</span></div>
                          <a
                            @click="openLoginModal"
                            class="t-y-btn t-y-btn-grey w-100"
                            >login Now</a
                          >
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- login Area End-->
  </main>
  <!-- <div class="app-page continer-body container-lg">
    <div class="row justify-content-center items-align">
      <div class="col-md-6 d-none d-md-flex">
        <div class="img-holder d-none d-md-block">
          <img
            src="../assets/banner-img.jpg"
            alt="banner-img"
          />
          <div class="banner-text">
            <h5>
              A Paragraph about what are the benefits of becoming a supplier on
              tradeinsafe.
            </h5>
            <div id="sub-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
              euismod bibendum laoreet.
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row justify-content-center">
          <div class="col-md-12 mb-4">
            <h2 class="color-default"><b>Sign Up for an account</b></h2>
            <p class="color-secondary">If you don’t have an account, Sign Up</p>
          </div>
          <div class="col-lg-10 col-md-10 col-sm-12">
            <form @submit.prevent="handleRegistration">
              <BaseInput
                label="First Name"
                type="text"
                v-model="first_name"
                :error="firstNameError"
                :required="true"
              />
              <BaseInput
                label="Last Name"
                type="text"
                v-model="last_name"
                :error="lastNameError"
                :required="true"
              />
              <BaseInput
                label="Email"
                type="email"
                v-model="email"
                :error="emailError"
                :required="true"
              />
              <BaseInput
                label="Password"
                type="password"
                v-model="password"
                :error="passwordError"
                :required="true"
              />
              <div class="row">
                <div class="d-grid gap-2 col-12 mx-auto">
                  <BaseButton
                    type="submit"
                    class="btn-default"
                  >
                    Sign Up
                  </BaseButton>
                </div>
              </div>
            </form>
            <div class="text-start pt-3">
              If you have an account?
              <span class="color-default router-link">
                <router-link to="/login">Login</router-link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</template>
<style scoped></style>
