<template>
  <p
    aria-live="assertive"
    class="errorMessage my-1 float-start"
    :id="id"
  >
    <slot />
  </p>
</template>

<script>
export default {
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  }
}
</script>
<style>

</style>
