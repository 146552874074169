<template>
  <ul class="submenu">
    <li
      v-for="(item, index) in items"
      :key="index"
    >
      <a @click="openProductList(item)"> {{ item.name }}</a>
      <CategoryComponent
        v-if="item?.subcategory && item?.subcategory.length && item.open"
        :items="item.subcategory"
        @item-clicked="openProductList"
      />
      <a
        @click="item.open = !item.open"
        class="mean-expand"
        v-if="item?.subcategory && item?.subcategory.length"
        href="#"
        style="font-size: 18px"
      >
        <i
          :style="item.open ? 'transform: rotate(45deg);' : ''"
          class="fal fa-plus"
        ></i>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "CategoryComponent",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    openProductList(item) {
      this.$emit("item-clicked", item);
    },
  },
};
</script>
