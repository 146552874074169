<script>
import { object, string } from "yup";
import { useField, useForm } from "vee-validate";
import _commonService from "../services/common_service";
import {
  closeLoginModal,
  openRegisterModal,
  openForgetPasswordModal,
} from "../state/authState";
export default {
  name: "LoginView",
  inject: ["notyf"],
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      btnDisabled: false,
      isLoading: false,
    };
  },
  setup() {
    const schema = object({
      email: string()
        .email("Please Enter Valid Email.")
        .required("Email is required."),
      password: string().max(15).min(6).required("Password is required."),
    });
    const {
      errors,
      values: loginForm,
      validate,
    } = useForm({
      validationSchema: schema,
      initialValues: {
        email: "",
        password: "",
      },
    });
    const { value: email, errorMessage: emailError } = useField("email");
    const { value: password, errorMessage: passwordError } =
      useField("password");

    return {
      errors,
      validate,
      loginForm,
      email,
      emailError,
      password,
      passwordError,
      closeLoginModal,
      openRegisterModal,
      openForgetPasswordModal,
    };
  },
  methods: {
    handleLogin() {
      this.btnDisabled = true;
      this.isLoading = true;
      this.validate().then((validationResult) => {
        if (!validationResult.valid) {
          this.btnDisabled = false;
          this.isLoading = false;
          return;
        }
        const url = "users/login";
        // this.activationEmail = this.loginForm.email
        this.login(url, this.loginForm);
      });
    },

    login(url, data) {
      this.isLoading = this.$loading.show();
      _commonService
        .noTokenPost(url, data)
        .then((response) => {
          if (response.data.status == 1) {
            this.isLoading.hide();
            this.$store.dispatch("login", response.data).then(async() => {
              this.isLoading = this.$loading.show();
              let cart= await this.getCartItems();
              this.notyf.success(response.data.message);
              this.$store.dispatch("updateCart", cart).then(() => {;
              // this.$router.push({ name: "home" });
                this.isLoading.hide();
                closeLoginModal();

              })
              .catch((error) => {
                this.isLoading.hide();
              });
            })
            .catch((error) => {
              this.isLoading.hide();
            });
          } else {
            this.isLoading.hide();
            this.notyf.error(response.data.message);
          }
        })
        .catch((error) => {
          console.log("error");
          this.isLoading.hide();
          this.notyf.error(error.message);
        });
    },
    getCartItems() {
      return new Promise((resolve) => {
        const url = `buyer/cart`;
        _commonService
          .get(url)
          .then((response) => {
            if (response.data.status === 1) {
              let cartArray = response.data.results || [];
              resolve(cartArray);
            } else {
              resolve([]);
              // this.notyf.error(response.data.message);
            }
          })
          .catch((error) => {
            resolve([]);
            console.log(error);
            // this.notyf.error(error.message);
          });
      });
    }
  },
};
</script>

<template>
  <main>
    <div
      class="modal-overlay"
      @click="closeLoginModal"
      :class="{ show: active }"
    ></div>

    <div
      class="modal fade"
      :class="active ? 'show' : ''"
      :style="active ? 'display: block !important;' : ''"
    >
      <div
        class="modal-dialog modal-dialog-centered product__modal"
        role="document"
      >
        <div class="modal-content">
          <div class="product__modal-wrapper p-relative">
            <div class="product__modal-close p-absolute">
              <button data-bs-dismiss="modal" @click="closeLoginModal" >
                <i class="fal fa-times"></i>
              </button>
            </div>
            <div class="product__modal-inner">
              <!-- login Area Strat-->
              <section class="">
                <div class="">
                  <div class="">
                    <div class="">
                      <div class="basic-login">
                        <h3 class="text-center mb-60">Login From Here</h3>
                        <form @submit.prevent="handleLogin">
                          <BaseInput
                            label="Email"
                            type="email"
                            v-model="email"
                            :error="emailError"
                            :required="true"
                          />
                          <BaseInput
                            label="Password"
                            type="password"
                            v-model="password"
                            :error="passwordError"
                            :required="true"
                          />
                          <!-- <label for="name">Email Address <span>**</span></label>
                <input
                  id="name"
                  type="text"
                  placeholder="Email address..."
                />
                <label for="pass">Password <span>**</span></label>
                <input
                  id="pass"
                  type="password"
                  placeholder="Enter password..."
                /> -->
                          <div class="login-action mb-20 fix">
                            <span class="forgot-login f-right">
                              <a @click="openForgetPasswordModal"
                                >Lost your password?</a
                              >
                            </span>
                          </div>
                          <button
                            type="submit"
                            class="t-y-btn w-100"
                          >
                            Login Now
                          </button>
                          <div class="or-divide"><span>or</span></div>
                          <a
                            @click="openRegisterModal"
                            class="t-y-btn t-y-btn-grey w-100"
                            >Register Now</a
                          >
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- login Area End-->
  </main>
  <!-- <div class="app-page continer-body container-lg">
    <div class="row justify-content-center items-align">
      <div class="col-md-6 d-none d-md-flex">
        <div class="img-holder d-none d-md-block">
          <img
            src="../assets/banner-img.jpg"
            alt="banner-img"
          />
          <div class="banner-text">
            <h5>
              A Paragraph about what are the benefits of becoming a supplier on
              tradeinsafe.
            </h5>
            <div id="sub-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
              euismod bibendum laoreet.
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row justify-content-center">
          <div class="col-md-12 mb-4">
            <h2 class="color-default"><b>Welcome back</b></h2>
            <p class="color-secondary">Log in if you already have an account</p>
          </div>
          <div class="col-lg-10 col-md-10 col-sm-12">
            <form @submit.prevent="handleLogin">
              <BaseInput
                label="Email"
                type="email"
                v-model="email"
                :error="emailError"
              />
              <BaseInput
                label="Password"
                type="password"
                v-model="password"
                :error="passwordError"
              />

              <div
                class="text-end mb-4 color-default forgot-password router-link"
              >
                <router-link to="/forgot-password"
                  >Forgot Password ?</router-link
                >
              </div>
              <div class="row">
                <div class="d-grid gap-2 col-12 mx-auto">
                  <BaseButton
                    type="submit"
                    class="btn-default"
                  >
                    Login In
                  </BaseButton>
                </div>
              </div>
            </form>
            <div class="text-start pt-3">
              Don’t have an account?
              <span class="color-default router-link">
                <router-link to="/register">Sign Up</router-link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</template>
