<template>
  <section :class=" section?._id=='home_section_3' ? 'blog__area pb-3 pt-4 grey-bg-2' :'blog__area pt-4 bg-white'" v-if="list.length">
    <div class="container" id="sliderContainer">
      <div class="row">
        <div class="col-xl-12 px-1">
          <div class="section__head d-flex justify-content-between">
            <div class="section__title">
              <h3>{{ title }}</h3>
              <p class="description" v-if="description">
                {{ description }}
              </p>
            </div>
            <div class="d-flex align-items-center ml-20 slider-nav" v-if="showMediaArrows">
              <div class="-prev">
                <button @click="goPrev">
                  <i class="fal fa-angle-left text-white"></i>
                </button>
              </div>
              <div class="-next">
                <button @click="goNext">
                  <i class="fal fa-angle-right text-white"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 px-1">
          <div class="blog__slider">
            <Splide
              ref="newssplide"
              :options="{
                type: 'slide',
                perPage: 1,
                rewind: true,
                autoWidth: true,
                arrows: false,
                pagination: false,
              }"
              aria-label="My Favorite Images"
            >
              <SplideSlide
                v-for="(item, index) in list"
                :key="index"
                @click="goToPageDetails(item)"
              >
                <div class="blog__item mx-2 me-2 mb-30" :class=" section?._id=='home_section_3' ? 'newsbox' :'articlebox'">
                  <div class="blog__thumb fix" :class=" section?._id=='home_section_3' ? 'border-top' :''">
                    <a>
                      <div
                        class="service_img"
                        :style="{
                          'background-image':
                            'url(&quot;' + item?.listing_image?.url + '&quot;)',
                        }"
                        v-if="item?.listing_image?.key"
                      ></div>
                      <div
                        class="news-no-image"
                        v-if="!item?.listing_image && !item?.listing_image?.key"
                      ></div>
                      <!-- <img
                      src="@/assets/template-assets/img/blog/blog-1.jpg"
                      alt=""
                    /> -->
                    </a>
                  </div>
                  <div class="blog__content white-bg" :class=" section?._id=='home_section_3' ? 'border-bottom' :''">
                    <h3>
                      <a>{{ item.page_name }}</a>
                    </h3>
                    <!-- <div class="blog__meta">
                      <span>Post Date:</span>
                      <span class="date">
                        {{ moment(item._created).format("DD MMM YY") }}</span
                      >
                    </div> -->
                    <p class="ellipsis-text">
                      {{ item?.teaser }}
                    </p>
                    <div class="text-end">
                      <a class="read-more">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import moment from "moment";
import { API_ENDPOINT, BASE_URL } from "../../environment";
export default {
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    list: {
      type: Array,
    },
    section: {
      type: String,
    },
  },
  data() {
    return {
      moment: moment,
      baseUrl: BASE_URL,
      showMediaArrows: false,
    };
  },
  created() {
    this.renderMediaArrows();
  },
  mounted() {
    window.addEventListener("resize", this.renderMediaArrows);
  },
  methods: {
    renderMediaArrows() {
      let ele= document.getElementById('sliderContainer');
      const adjustedWindowWidth = Math.round((ele?.offsetWidth||0) - 0);
      const cardSize = 300
      const maxCards = Math.floor(adjustedWindowWidth / cardSize)
      this.showMediaArrows =
        (this.list.length || 0) > maxCards ? true : false
    },
    goPrev() {
      this.$refs.newssplide.go("-1");
    },
    goNext() {
      this.$refs.newssplide.go("+1");
    },
    goToPageDetails(page) {
      this.$router.push(`/page/${page._id}`);
    },
  },
 
};
</script>
<style>
.read-more{
  font-size: 12px;
  color: #353535;
  text-decoration: underline;
  padding: 5px 0;
}
.border-top{
  border-radius: 10px 10px 0 0;
}
.border-bottom{
  border-radius: 0 0 10px 10px;
}
.newsbox{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: none !important;
  border-radius: 0 0 10px 10px;
}

</style>