<template>
  <div class="control-box">
    <label
      v-if="label"
      :for="uuid"
      class="cust-label form-label"
    >
      {{ label }} <span v-if="required">**</span>
    </label>

    <input
      class="form-control"
      v-bind="{
        ...$attrs,
        onInput: updateValue,
      }"
      :id="uuid"
      :value="modelValue"
      :aria-describedby="error ? `${uuid}-error` : null"
      :aria-invalid="error ? true : false"
      :class="{ error }"
    />
    <BaseErrorMessage
      v-if="error"
      :id="`${uuid}-error`"
    >
    </BaseErrorMessage>
    <div class="text-start errorMessage">{{ error }}</div>
  </div>

  <!-- <div>{{error}}</div> -->
</template>

<script>
import SetupFormComponent from "@/features/SetupFormComponent";
import UniqueID from "@/features/UniqueID";
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
  },
  setup(props, context) {
    const { updateValue } = SetupFormComponent(props, context);
    const uuid = UniqueID().getID();
    return {
      updateValue,
      uuid,
    };
  },
};
</script>
<style>
.form-control {
  width: 100%;
  height: 48px;
  border: 2px solid #eaedff;
  color: #6f7172;
  padding: 0 20px;
  margin-bottom: 10px;
  outline: none;
}
</style>
<style scoped>
/* .control-box {
  position: relative;
}
.cust-label {
  margin-bottom: 0;
  font-size: 14px;
  margin-top: -10px;
  position: absolute;
  left: 18px;
  background: white;
  padding: 0 5px;
  line-height: 18px;
} */
.form-control {
  line-height: 1.9;
}
.errorMessage {
  color: red;
  font-size: 12px;
  line-height: initial;
  padding: 3px 0;
}
</style>
