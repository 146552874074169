<template>
  <li>
    <div class="block cursor-pointer pt-1 text-white" :class="selectedPage == 'home' ? 'bottom-border' : ''">
      <span
        class="text-base font-medium titlecase"
        @click="$router.push('/')"
        >Home</span
      >
    </div>
  </li>

  <CategoryDropdown
    v-if="displayOtherTabs"
    :categories="categories"
    :selectedPage="selectedPage"
    name="Products"
    @open="$emit('open', $event)"
    @all="$emit('all', $event)"
  />
  
  <li @click="$router.push('/ready-to-ship')" v-if="displayOtherTabs">
    <div class="block cursor-pointer pt-1 text-white"  :class="selectedPage == 'ready_ship' ? 'bottom-border' : ''">
      <span
        class="text-base font-medium titlecase"
        >Ready to Ship
      </span>
    </div>
  </li>

  <!-- <li @click="$router.push('/products')" v-if="displayOtherTabs">
    <div class="block cursor-pointer pt-1 text-white"  :class="selectedPage == 'products' ? 'bottom-border' : ''">
      <span
        class="text-base font-medium titlecase"
        >Products
      </span>
    </div>
  </li> -->

  <li @click="$router.push('/sellers')" v-if="displayOtherTabs">
    <div class="block cursor-pointer pt-1 text-white"  :class="selectedPage == 'sellers' ? 'bottom-border' : ''">
      <span
        class="text-base font-medium titlecase"
        >Manufactures
      </span>
    </div>
  </li>
  <!-- <li @click="$router.push('/contact')">
    <div class="block cursor-pointer pt-1 text-white"  :class="selectedPage == 'contact' ? 'bottom-border' : ''">
      <span
        class="text-base font-medium titlecase"
        >Contact
      </span>
    </div>
  </li> -->
  <li v-if="selectedPage != 'cart' " >
    <label
      class="position-relative float-start pointer d-flex"
      v-if="$store.getters.getCartArrayLength"
      @click="$router.push('/cart')"
    >
      <span
        style="font-size: 30px"
        class="material-icons text-white"
        >shopping_cart</span
      >
      <span class="position-absolute badge bg-secondary cartbadge">{{
        $store.getters.getCartArrayLength
      }}</span>
    </label>
  </li>
</template>

<script>
import CategoryDropdown from "./CategoryDropdown.vue";
export default {
  name: "Dropdown",
  components: { CategoryDropdown },
  props: {
    categories: {
      type: Object,
      required: true,
    },
    selectedPage: {},
    displayOtherTabs:{
      type:Boolean,
      default:true,
    }
  },
  data() {
    return {
      isHovered: false,
    };
  },
  computed: {
    hasSubcategory() {
      return this.item.subcategory && this.item.subcategory.length;
    },
  },
};
</script>

<style scoped>
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.bottom-border{
  border-bottom: 2px solid #ffffff;
}
</style>
