<template>
      <li 
        class="relative header_list"
        @mouseover="isHovered = true"
        @mouseleave="isHovered = false"
        v-if="$store.getters.isAuthenticated"
        >
        <div class="d-flex navigation-block pointer " >
            <a>
                <div class="icon-block">
                    <i id="icon" class="far fa-money-bill-alt"></i>
                </div>
                <div class="text-blockkk">
                    <div class="sub-label">
                        <span class="clickable"> Currency ({{ selectedCurrency }})</span>
                    </div>
                </div>
            </a>
        </div>
        <div v-show="isHovered" class="bg-white p-2 currency-list">
            <div class="d-block">
                <div class="control-box ">
                    <label for="currency" class="cust-label">Select Currency</label>
                        <Multiselect
                        class="currency-dd"
                        :searchable="true" 
                        @search-change="onSearchChange" 
                        @select="onSelect"
                        @clear="onClear"
                        v-model="selected_currency"
                        :options="currencyList"
                        />
                </div>
            </div>
        </div>
      </li>

      <li  class="header_list relative " 
            @mouseover="isHovered = true"
            @mouseleave="isHovered = false" 
            v-if="!$store.getters.isAuthenticated">
            <div class="d-flex login-block pointer "  >
                <div class="d-inline-flex icon-block me-3">
                    <i id="icon" class="far fa-money-bill-alt"></i>
                </div>
                <div class="d-inline-flex text-blockk">
                  <div class="label"> 
                    <span class="clickable">Currency</span>
                  </div>
                  <div class="sub-label">
                    <span class="clickable" v-if="selectedCurrency">({{ selectedCurrency }})</span>
                  </div>
                </div>
            </div>
            <div v-show="isHovered" class="bg-white p-2 currency-list">
                <div class="d-block">
                    <div class="control-box">
                        <label for="currency" class="cust-label">Select Currency</label>
                            <Multiselect
                            class="currency-dd"
                            :searchable="true" 
                            @search-change="onSearchChange" 
                            @select="onSelect"
                            @clear="onClear"
                            v-model="selected_currency"
                            :options="currencyList"
                            />
                    </div>
                </div>
            </div>
        </li>
  </template>
  
  <script>
  import Multiselect from '@vueform/multiselect'

    export default {
        name: "CurrencyComponent",
        components:{
            Multiselect
        },
        props: {
            currencyList: {
                type: Array,
                default: () => [],
            },
            baseCurrency: {
                type: String,
                default: '',
            },
            selectedCurrency: {
                type: String,
                default: '',
            },
        },
        methods: {
            onSearchChange(){

            },
            onSelect(selectedItem){
                if(selectedItem){
                    this.$emit('onSelect', selectedItem);
                }
            },
            onClear(){
                this.selected_currency = '';
            }
        },
        created() {
        },
        data() {
            return {
                isHovered: false,
                selected_currency: '',
            };
        },
        computed: {
            selected_currency(){
                return this.selectedCurrency;
            },
            selectedCurrency(){
                return this.$store.getters?.getSelectedCurrency ? this.$store.getters.getSelectedCurrency.currency : '';  
            }
        },

    };
  </script>

<style lang="scss">
.login-block{
  .icon-block{
    width: 45px;
    #icon{
      font-size: 35px;
      font-weight: 300 !important;
      line-height: 35px;
    }
  }
  .text-blockk{
  flex-direction: column;
  
  .label{
    line-height: initial;
    padding: 3px 0 2px;
    font-size: 10px;
    .span{
      font-size: 12px;
      color: #b9b8b8;
    }
  }

  .sub-label{
    line-height: initial;
    font-size: 14px;
    font-weight: 600;
  }

}
}
.navigation-block{
  flex-direction: column; 
  .icon-block{
      height: 35px;
      width: 35px;
      margin: auto;

      #icon{
        font-size: 25px;
        font-weight: 300 !important;
      }
  }

  .text-blockkk .sub-label{
    line-height: initial;
    font-size: 14px;
    font-weight: 600;
  }
}
.currency-list{
    box-shadow: rgba(0, 0, 0, 0.26) 0px 1px 4px;
    width: max-content;
    height: max-content;
    min-height: 50px;
    max-height: max-content;
    position: absolute;
    // height: 130px;
    z-index: 5;
    width: 250px;
    left: -20px;
}
.currency-list .list{
    display: block !important;
    width: 100% !important;
}
.cust-label ul li{

}

// .cust-label {
//   font-size: 14px;
//   position: absolute;
//   background: white;
//   line-height: 14px;
//   z-index: 1;
// }
// .control-box {
//     position: relative;
// }
// .product-listbox{
//     width: 100%;
//     // height: 43px;
//     // margin-top: 5px;
// }
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
  