import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
// import * as NProgress from 'nprogress'
import store from "@/store";
import _commonService from "../services/common_service";
import useNotyf from "../composable/useNotyf";

const notyf = useNotyf();
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { requiresWhoami: true },
  },
  {
    path: "/activate-user",
    name: "activate-user",
    component: () =>
      import(
        /* webpackChunkName: "verify-account" */ "../views/VerifyAccountView.vue"
      ),
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ "../views/ResetPasswordView.vue"
      ),
  },
  {
    path: "/store-details/:id",
    name: "store-details",
    component: () =>
      import(
        /* webpackChunkName: "store-details" */ "../views/StoreDetailsView.vue"
      ),
    meta: { requiresWhoami: true },
  },
  {
    path: "/sellers",
    name: "sellers",
    component: () =>
      import(/* webpackChunkName: "sellers" */ "../views/SellersView.vue"),
    meta: { requiresWhoami: true },
  },
  {
    path: "/product-details/:id",
    name: "product-details",
    component: () =>
      import(
        /* webpackChunkName: "product-details" */ "../views/ProductDetails.vue"
      ),
    meta: { requiresWhoami: true },
  },
  {
    path: "/cart",
    name: "cart",
    component: () =>
      import(/* webpackChunkName: "cart" */ "../views/CartView.vue"),
    meta: { requiresWhoami: true, requireAuth: true },
  },
  {
    path: "/contact/:id",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "cart" */ "../views/Contact.vue"),
    meta: { requiresWhoami: true },
  },
  {
    path: "/profile/:tab?/:type?/:messageId?",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "cart" */ "../views/Profile.vue"),
    meta: { requiresWhoami: true },
  },
  {
    path: "/checkout/:id",
    name: "checkout",
    component: () =>
      import(/* webpackChunkName: "checkout" */ "../views/CheckoutView.vue"),
    meta: { requiresWhoami: true, requireAuth: true },
  },
  {
    path: "/page/:id",
    name: "page",
    component: () =>
      import(/* webpackChunkName: "checkout" */ "../views/PageView.vue"),
    meta: { requiresWhoami: true },
  },
  {
    path: "/product-list/:id?",
    name: "product-list",
    component: () =>
      import(/* webpackChunkName: "checkout" */ "../views/AllProductList.vue"),
    meta: { requiresWhoami: true },
  },
  {
    path: "/order-success/:orderId",
    name: "order-success",
    component: () =>
      import(/* webpackChunkName: "checkout" */ "../views/OrderSuccessView.vue"),
    meta: { requiresWhoami: true },
  },
  {
    path: "/products",
    name: "Products",
    component: () =>
      import(/* webpackChunkName: "checkout" */ "../views/Products.vue"),
    meta: { requiresWhoami: true },
  },
  {
    path: "/ready-to-ship",
    name: "ready-to-ship",
    component: () =>
      import(/* webpackChunkName: "checkout" */ "../views/ReadyToShip.vue"),
    meta: { requiresWhoami: true },
  },
  {
    path: "/messages-list/:type/:messageId",
    name: "messages-list",
    component: () =>
      import(
        /* webpackChunkName: "checkout" */ "../views/MessagesView.vue"
      ),
    meta: { requiresWhoami: true, requireAuth: true },
  },
  // {
  //   path: "/messages-details/:type/:messageId",
  //   name: "messages-details",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "checkout" */ "../views/MessageDetailsView.vue"
  //     ),
  //   meta: { requiresWhoami: true, requireAuth: true },
  // },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // NProgress.start()

  if (to.matched.some((record) => record.meta.requiresWhoami)) {
    let token = localStorage.getItem("token") || null;
    if (token) {
      if (store.state.user === null) {
        checkAuth(to, from, next);
      } else {
        next();
      }
    } else {
      next();
      store.dispatch("updateCart", []);
    }
  } else {
    next();
  }
});

function checkAuth(to: any, from: any, next: any) {
  const url = `users/whoami`;
  _commonService
    .get(url)
    .then((response) => {
      if (response.data.status === 1) {
        store.dispatch("login", response.data.result).then((data) => {
          if (store.state.user === null) {
            initiateCart();
          }

          store.commit("SET_USER", {
            user: response.data?.result.user,
          });

          next();
        });
      } else {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        store.dispatch("updateCart", []);
        next();
      }
    })
    .catch((error) => {
      next();
      console.log(error);
      store.dispatch("autoAuthUser");
      notyf.error("Session expired. Please login");
    });
}

async function initiateCart() {
  let cartArray = await getCartItems();
  store.dispatch("updateCart", cartArray);
}

function getCartItems() {
  return new Promise((resolve) => {
    const url = `buyer/cart`;
    console.log(url);
    _commonService
      .get(url)
      .then((response) => {
        if (response.data.status === 1) {
          let cartArray = response.data.results || [];
          resolve(cartArray);
        } else {
          resolve([]);
          // this.notyf.error(response.data.message);
        }
      })
      .catch((error) => {
        resolve([]);
        console.log(error);
        // this.notyf.error(error.message);
      });
  });
}

router.afterEach(() => {
  // NProgress.done()
});

export default router;
