<script>
import { object, string } from "yup";
import { useField, useForm } from "vee-validate";
import _commonService from "../services/common_service";
import {
  openLoginModal,
  openRegisterModal,
  closeForgetPasswordModal,
} from "../state/authState";
export default {
  name: "ForgotPasswordView",
  inject: ["notyf"],

  data() {
    return {
      btnDisabled: false,
      isLoading: false,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const schema = object({
      email: string()
        .email("Please Enter Valid Email.")
        .required("Email is required."),
    });
    const {
      errors,
      values: forgotPasswordForm,
      validate,
    } = useForm({
      validationSchema: schema,
      initialValues: {
        email: "",
      },
    });
    const { value: email, errorMessage: emailError } = useField("email");

    return {
      errors,
      validate,
      forgotPasswordForm,
      email,
      emailError,
      openLoginModal,
      openRegisterModal,
      closeForgetPasswordModal,
    };
  },
  methods: {
    handleSubmit() {
      this.btnDisabled = true;
      this.isLoading = true;
      this.validate().then((validationResult) => {
        if (!validationResult.valid) {
          this.btnDisabled = false;
          this.isLoading = false;
          return;
        }
        const url = "users/forgot-password";
        // this.activationEmail = this.forgotPasswordForm.email
        this.forgotPassword(url, this.forgotPasswordForm);
      });
    },

    forgotPassword(url, data) {
      this.isLoading = this.$loading.show();
      _commonService
        .noTokenPost(url, data)
        .then((response) => {
          if (response.data.status == 1) {
            this.notyf.success(response.data.message);
            this.isLoading.hide();
          } else {
            this.isLoading.hide();
            this.notyf.error(response.data.message);
          }
        })
        .catch((error) => {
          console.log("error");
          this.isLoading.hide();
          this.notyf.error(error.message);
        });
    },
  },
};
</script>

<template>
  <main>
    <div
      class="modal-overlay"
      @click="closeForgetPasswordModal"
      :class="{ show: active }"
    ></div>

    <div
      class="modal fade"
      :class="active ? 'show' : ''"
      :style="active ? 'display: block !important;' : ''"
    >
      <div
        class="modal-dialog modal-dialog-centered product__modal"
        role="document"
      >
        <div class="modal-content">
          <div class="product__modal-wrapper p-relative">
            <div class="product__modal-close p-absolute">
              <button
                data-bs-dismiss="modal"
                @click="closeForgetPasswordModal"
              >
                <i class="fal fa-times"></i>
              </button>
            </div>
            <div class="product__modal-inner">
              <!-- login Area Strat-->
              <section class="">
                <div class="">
                  <div class="">
                    <div class="">
                      <div class="basic-login">
                        <h3 class="text-center mb-60">Forgot From Here</h3>
                        <form @submit.prevent="handleSubmit">
                          <BaseInput
                            label="Email"
                            type="email"
                            v-model="email"
                            :error="emailError"
                          />

                          <button
                            type="submit"
                            class="t-y-btn w-100"
                          >
                            Submit
                          </button>
                          <div class="or-divide"><span>or</span></div>
                          <a
                            @click="openLoginModal"
                            class="t-y-btn t-y-btn-grey w-100"
                            >Login Now</a
                          >
                          <a
                            @click="openRegisterModal"
                            class="t-y-btn t-y-btn-grey mt-3 w-100"
                            >Register Now</a
                          >
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- login Area End-->
  </main>
  <!-- <div class="app-page continer-body container-lg">
    <div class="row justify-content-center items-align">
      <div class="col-md-6 d-none d-md-flex">
        <div class="img-holder d-none d-md-block">
          <img
            src="../assets/banner-img.jpg"
            alt="banner-img"
          />
          <div class="banner-text">
            <h5>
              A Paragraph about what are the benefits of becoming a supplier on
              tradeinsafe.
            </h5>
            <div id="sub-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
              euismod bibendum laoreet.
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row justify-content-center">
          <div class="col-md-12 mb-4">
            <h2 class="color-default"><b>Forgot Password ?</b></h2>
            <p class="color-secondary">Please enter your email address</p>
          </div>
          <div class="col-lg-10 col-md-10 col-sm-12">
            <form @submit.prevent="handleSubmit">
              <BaseInput
                label="Email"
                type="email"
                v-model="email"
                :error="emailError"
              />

              <div class="row">
                <div class="d-grid gap-2 col-12 mx-auto">
                  <BaseButton
                    type="submit"
                    class="btn-default"
                    :disabled="btnDisabled"
                  >
                    Submit
                  </BaseButton>
                </div>
              </div>
            </form>
            <div style="padding: 20px 0">
              <span
                style="font-size: 1.05rem"
                class="router-link"
                >Remember password?
                <RouterLink :to="{ name: 'login' }"> Log In </RouterLink></span
              ><br />
              <span
                style="font-size: 1.05rem"
                class="router-link"
                >Don't have an account?
                <RouterLink :to="{ name: 'register' }">
                  Sign Up
                </RouterLink></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</template>
