<template>
   <div class="w-100 slider-height" :class="isMobileView ? 'd-none':''">
    <div v-if="slides.length">
      <Splide
        :options="{
          rewind: true,
          arrows: false,
          autoplay: true,
          interval: 3000,
          speed: 1000,
        }"
        aria-label="My Favorite Images"
      >
        <SplideSlide
          v-for="(slide, key) in slides"
          :key="key"
        >
          <img
            :src="slide.image.url"
            alt="slider"
            class="w-100 h-300 object-cover"
          />
          <!-- <div :style="{'background-image':'url(&quot;' + slide.image.url +'&quot;)'}" class="w-100 h-300 object-cover"> </div> -->
        </SplideSlide>
      </Splide>
    </div>
  </div>
</template>

<script>
import _commonService from "../services/common_service";
export default {
  data() {
    return {
      isLoading: true,
      slides: [],
    };
  },
  created() {
    this.getSlider();
  },
  inject: ["notyf"],
  methods: {
    getSlider() {
      const url = "/home-slides";
      _commonService
        .get(url)
        .then((response) => {
          if (response.data.status == 1) {
            this.slides = response.data.results;

            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.notyf.error(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.notyf.error(error.message);
        });
    },
  },
  watch:{
    isMobileView(old,n){
        console.log(old,n);
    }
  },
  computed:{
    isMobileView() {
      return (Math.round(window.innerWidth-0) < 576) ?true:false;
    }
  }
};
</script>
<style scoped>
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
.slider-height {
  height: 300px;
}
.h-300 {
  height: 300px;
  /* max-height: 300px; */
  /* max-width: -webkit-fill-available;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain; */
}
@media (max-width: 575px) {
  .h-300 {
    height: 100px;
  }
  .slider-height {
    height: 120px;
    padding: 15px 0;
  }
}
</style>
