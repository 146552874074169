<template>
    <li 
      class="relative header_list"
      @mouseover="isHovered = true"
      @mouseleave="isHovered = false"
      v-if="$store.getters.isAuthenticated"
      >
      <div class="d-flex navigation-block pointer " >
          <a>
              <div class="icon-block">
                  <!-- <i id="icon" class="far fa-money-bill-alt"></i> -->
                  <i id="icon" class="far fa-location"></i>
              </div>
              <div class="text-blockkk">
                  <div class="sub-label">
                      <span class="clickable" v-if="selectedCountry"> Country ({{ selectedCountry }}) </span>
                      <span class="clickable" v-else> Country</span>
                  </div>
              </div>
          </a>
      </div>
      <div v-show="isHovered" class="bg-white p-2 country-list">
          <div class="d-block">
              <div class="control-box">
                  <label for="country" class="cust-label">Select Country</label>
                      <Multiselect
                      :searchable="true" 
                      @search-change="onSearchChange" 
                      @select="onSelect"
                      @clear="onClear"
                      v-model="selected_country"
                      :options="countryList"
                      />
              </div>
          </div>
      </div>
    </li>

    <li  class="header_list relative " 
          @mouseover="isHovered = true"
          @mouseleave="isHovered = false" 
          v-if="!$store.getters.isAuthenticated">
          <div class="d-flex login-block pointer "  >
              <div class="d-inline-flex icon-block me-2">
                  <!-- <i id="icon" class="far fa-money-bill-alt"></i> -->
                  <i id="icon" class="far fa-location"></i>
              </div>
              <div class="d-inline-flex text-blockk">
                <div class="label"> 
                  <span class="clickable">Country</span>
                </div>
                <div class="sub-label">
                  <span class="clickable" v-if="selectedCountry">({{ selectedCountry }})</span>
                </div>
              </div>
          </div>
          <div v-show="isHovered" class="bg-white p-2 country-list">
              <div class="d-block">
                  <div class="control-box">
                      <label for="country" class="cust-label">Select Country</label>
                          <Multiselect
                          :searchable="true" 
                          @search-change="onSearchChange" 
                          @select="onSelect"
                          @clear="onClear"
                          v-model="selected_country"
                          :options="countryList"
                          />
                  </div>
              </div>
          </div>
      </li>
</template>

<script>
import Multiselect from '@vueform/multiselect'

  export default {
      name: "CurrencyComponent",
      components:{
          Multiselect
      },
      props: {
          countryList: {
              type: Array,
              default: () => [],
          },
          baseCountry: {
              type: String,
              default: '',
          },
          selectedCountry: {
              type: String,
              default: '',
          },
      },
      methods: {
          onSearchChange(){

          },
          onSelect(selectedItem){
              if(selectedItem){
                  this.$emit('onSelect', selectedItem);
              }
          },
          onClear(){
              this.selected_country = '';
              this.$emit('onClear', '');
          }
      },
      created() {
      },
      data() {
          return {
              isHovered: false,
              selected_country: '',
          };
      },
      computed: {
          selected_country(){
              return this.selectedCountry;
          },
          selectedCountry(){
              return this.$store.getters?.getSelectedCountry ? this.$store.getters.getSelectedCountry.name : '';  
          }
      },

  };
</script>

<style lang="scss">
.login-block{
.icon-block{
  width: 45px;
  #icon{
    font-size: 35px;
    font-weight: 300 !important;
    line-height: 35px;
  }
}
.text-blockk{
flex-direction: column;

.label{
  line-height: initial;
  padding: 3px 0 2px;
  font-size: 10px;
  .span{
    font-size: 12px;
    color: #b9b8b8;
  }
}

.sub-label{
  line-height: initial;
  font-size: 14px;
  font-weight: 600;
}

}
}
.navigation-block{
flex-direction: column; 
.icon-block{
    height: 35px;
    width: 35px;
    margin: auto;

    #icon{
      font-size: 25px;
      font-weight: 300 !important;
    }
}

.text-blockkk .sub-label{
  line-height: initial;
  font-size: 14px;
  font-weight: 600;
}
}
.country-list{
  box-shadow: rgba(0, 0, 0, 0.26) 0px 1px 4px;
  width: max-content;
  height: max-content;
  min-height: 50px;
  max-height: max-content;
  position: absolute;
  // height: 130px;
  z-index: 5;
  width: 250px;
  left: -20px;
}
.country-list .list{
  display: block !important;
  width: 100% !important;
}
.cust-label ul li{

}

</style>
<style src="@vueform/multiselect/themes/default.css"></style>
