<script>
export default {
    props:{
        recordsFetching:{
            type:Boolean,
            default:true
        },
        type:{
            type:String,
            default:''
        }
    
    },
}
</script>
<template>
    <section class="best__sell">
        <div class="row">
          <div class="col-xl-12">
            <div class="section__head d-md-flex justify-content-between">
              <template v-if="type">
                <div class="section__title d-inline-flex">
                  <h3 v-if="type == 'new-arrivals'"><b>New Arrivals</b></h3>
                  <h3 v-else-if="type == 'gifts'"><b>Gifts</b></h3>
                  <h3 v-else-if="type == 'trending-items'"> <b>Trending Items</b></h3>
                  <h3 v-else-if="type == 'sale-items'"><b>Items On Sale</b></h3>
                  <h3 v-else-if="type == 'related-products'"><b>You May Also Like</b></h3>
                </div>
              </template>

            </div>
          </div>
        </div>
     <div class="row" v-if="recordsFetching" >
          <div class="col-xl-12">
            <div class="tab-content">
              <div class="tab-pane fade show active" >
                <div class="product__slider">
                  <Splide
                    ref="splide"
                    :options="{
                      type: 'slide',
                      perPage: 1,
                      rewind: true,
                      autoWidth: true,
                      arrows: false,
                      pagination: false,
                    }"
                    aria-label="Products"
                    :class="type ? '' : 'product__grid'"
                  >
                    <SplideSlide
                      v-for="(item, index) in 20"
                      :key="index"
                    >
                      <div class="product__item white-bg">
                        <div class="product__thumb p-relative card-bg">
                          <a class="w-img">
                            <div class="product_img"></div>
                          </a>
                          
                        </div>
                        <div class="product__content text-center dummy_list">
                          <div class="product_price">
                            <span class="price" ></span>
                            <span class="price" ></span>
                          </div>
                          <div class="product_name"></div>
                        </div>
                      </div>
                    </SplideSlide>
                  </Splide>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
</template>
