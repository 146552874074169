<template>
  <li
    class="relative group  mx-0"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
    :class=" isHovered ? 'selected-item' : ''"
  >
    <div
      @click="$emit('open', item)"
      class="flex cursor-pointer justify-between w-full py-2 px-3 text-black"
    >
      {{ item.name }}
      <span
        v-if="hasSubcategory"
        class="ms-4"
      >
        <i
          style="transform: rotate(-90deg) translateY(-50%)"
          class="fas fa-angle-down"
        ></i>
      </span>
    </div>
    <ul
      v-if="hasSubcategory && isHovered"
      :class="'absolute left-105 top-0 z-50 bg-white sublist px-0 py-2.5 '"
    >
      <component
        v-for="(subItem, index) in item.subcategory"
        :key="index"
        :item="subItem"
        :is="'SubDropdown'"
        @open="$emit('open', $event)"
      />
    </ul>
  </li>
</template>

<script>
export default {
  name: "SubDropdown",
  props: {
    item: {
      type: Object,
      required: true,
    },
    index:{
      type: Number,
    }
  },
  data() {
    return {
      isHovered: false,
    };
  },
  computed: {
    hasSubcategory() {
      return this.item.subcategory && this.item.subcategory.length;
    },
    indexValue(){
      // return 'left-105';
      return this.index==0 ? 'left-full' : 'left-105';
    }
  },
};
</script>

<style scoped>
.sublist{
  height: max-content;
  /* min-height: 350px; */
  max-height: max-content;
  width:250px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.sublist li{
  display: block;
    width: 100% !important;
}
.left-105 {
  left: 100%;
  /* margin-top: 6px; */
}
.left-250 {
  left:250px
}

.selected-item{
  background: #ebebeb;
}
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
</style>
