import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import 'notyf/notyf.min.css';
import 'notyf/notyf.min.js'
// import 'toastr/toastr.min.css'
// import 'toastr/toastr.min.js'
// import { Toastr } from "toastr";
import { LoadingPlugin } from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
// createApp(App).use(store).use(router).mount("#app");
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import { Notyf } from "notyf";
import "@splidejs/vue-splide/css";
// import $ from "jquery";

// // template
// // css
// import './assets/template-assets/css/preloader.css'
import './assets/template-assets/css/bootstrap.min.css'
import './assets/template-assets/css/slick.css'
import './assets/template-assets/css/backToTop.css'
import './assets/template-assets/css/meanmenu.css'
// import './assets/template-assets/scss/components/_menu.scss'
import './assets/template-assets/css/nice-select.css'
import './assets/template-assets/css/owl.carousel.min.css'
// import './assets/template-assets/css/animate.min.css'
// import './assets/template-assets/css/jquery.fancybox.min.css'
import './assets/template-assets/css/fontAwesome5Pro.css'
// import './assets/template-assets/css/ui-range-slider.css'
import './assets/template-assets/css/default.css'
import './assets/template-assets/css/style.css'
import './assets/template-assets/scss/components/_slider.scss'
// // javascript
import './assets/template-assets/js/vendor/jquery-3.6.0.min.js'
import './assets/template-assets/js/vendor/waypoints.min.js'
import './assets/template-assets/js/bootstrap.bundle.min.js'
import './assets/template-assets/js/meanmenu.js'
// import './assets/template-assets/js/slick.min.js'
// import './assets/template-assets/js/backToTop.js'
import './assets/template-assets/js/jquery.fancybox.min.js'
// import './assets/template-assets/js/countdown.js'
import './assets/template-assets/js/nice-select.min.js'
import './assets/template-assets/js/isotope.pkgd.min.js'
// import './assets/template-assets/js/owl.carousel.min.js'
import './assets/template-assets/js/jquery-ui-slider-range.js'
import './assets/template-assets/js/ajax-form.js'
import './assets/template-assets/js/wow.min.js'
import './assets/template-assets/js/imagesloaded.pkgd.min.js'
// import './assets/template-assets/js/main.js'
console.log('main.ts run')
// // template
import VueSplide from '@splidejs/vue-splide';

const requireComponent = require.context(
  './components',
  false,
  /Base[A-Z]\w+\.(vue|js)$/
)

const app = createApp(App)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)

  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1'))
  )

  app.component(componentName, componentConfig.default || componentConfig)
})

const notyf = new Notyf({
  duration: 5000, // Set your global Notyf configuration here
  position:{x:'right',y:'top'},
})
// const toastr = new Toastr({
//   closeDuration : 5000, // Set your global Notyf configuration here
//   preventDuplicates : true,
// })
app.provide('notyf', notyf)
app.provide('toastr', notyf)
app.use(store)
app.use(LoadingPlugin, { color: '#D63547' });
app.use(router)

app.use(VueSplide)
// router.beforeEach(async (to, from, next) => {
//   // window.location.reload();
//   console.log('load')
//   // Load and execute your JavaScript files here
//   try {
//     await loadAndExecuteJSFiles(); // Custom function to load and execute the JavaScript files
//     next();
//   } catch (error) {
//     console.error("Failed to load and execute JavaScript files:", error);
//     next(false); // Cancel the navigation if there was an error
//   }
// });

// async function loadAndExecuteJSFiles() {
//   // Dynamically load and execute your JavaScript files here
//   // You can use dynamic import or a script loader to load the files
//   // For example:
//   console.log('load main.js')
//   await import('./assets/template-assets/js/main.js' as './assets/template-assets/js/main.d.ts');
//   // Load other JavaScript files as needed
// }



app.mount('#app')