<script>
import { defineComponent } from "vue";
import _commonService from "../services/common_service";
import { API_ENDPOINT, BASE_URL } from "../../environment";

export default defineComponent({
  name: "ProductListView",
  components: {
  },
  props: {
    storeId: String,
    type: String,
    // cartArray: Array,
    storeList: Array,
    categoryId: String,
    isReadyToShip: Boolean,
    items: {
      type: Array,
      default: () => [],
    },
  },

  inject: ["notyf"],
  data() {
    return {
      watchersActive: false,
      recordsFetching:true,
      tabs: ["New Arrival", "Featured", "Hot Sale", "Random"],
      activeTab: "New Arrival",
      bannerImage: {},
      isLoading: false,
      page: 1,
      pageSize: 20,
      sortBy: "_created",
      sortOrder: "DESC",
      baseUrl: BASE_URL,
      paginationObj: {},
      productDetails: {},
      selectedVariant: {},
      productsList: [],
      alreadyAddedToCart: false,
      nonAvailableOptions: [],
      // currency: "INR",
      qty: 1,
      productPrice: 0,
      userId: "",
      variantsAvailable: false,
      deleteItemObj: {},
      activeData: {},
      showModal: false,
      showArrows: false,
    };
  },
  watch: {
    routeSearchText: {
      handler() {
        if (!this.watchersActive) return; // exit early if watchers aren't active

        if (this.routeSearchText || this.routeId) {
          this.page=1;
          this.getProductList();

        }
        if(!this.routeSearchText || this.routeSearchText===''){
          this.$router.push("/");
        }
      },
    },
    routeId: {
      handler() {
        if (!this.watchersActive) return; // exit early if watchers aren't active

        if (this.routeSearchText || this.routeId) {
          this.page=1;
          this.getProductList();
        }
        
      },
    },
    currency(newval, old){
        this.page=1;
        this.getProductList();
    },
    selectedCountry(newval, old){
      this.page=1;
      this.getProductList();
    },
    categoryId(newval, old){
      this.page=1;
      this.getProductList();
    }
  },
  computed: {
    routeId() {
      return this.$route?.params?.id;
    },
    routeSearchText() {
      return this.$route?.query?.searchText;
    },
    user() {
      return this.$store.state?.user;
    },
    cartArray() {
      return this.$store.getters?.getCartArray
        ? this.$store.getters?.getCartArray
        : [];
    },
    currency(){
      return this.$store.getters?.getSelectedCurrency ? this.$store.getters.getSelectedCurrency.currency : '';  
    },
    selectedCurrency(){
      return this.$store.getters?.getSelectedCurrency ? this.$store.getters.getSelectedCurrency : {};  
    },
    selectedCountry(){
      return this.$store.getters?.getSelectedCountry?.name ? this.$store.getters?.getSelectedCountry?.name : '';  
    },
 
  },
  // watch:{
  //   currency(newval, old){
  //       this.page=1;
  //       this.getProductList();
  //   },
  //   selectedCountry(newval, old){
  //     this.page=1;
  //     this.getProductList();
  //   },
  //   categoryId(newval, old){
  //     this.page=1;
  //     // console.log('newval', newval,'old', old);
  //     this.getProductList();
  //   }
  // },
 created() {
    this.showRelatedItemsArrows();
  },
  mounted() {
    this.userId = this.user ? this.user._id : "";
    this.getProductList();
    this.watchersActive = true; // activate watchers
    if(this.type){
      window.addEventListener("resize", this.showRelatedItemsArrows);
    }
 
  },
  methods: {
    showRelatedItemsArrows() {
      let ele= document.getElementById('sliderContainer');
      const adjustedWindowWidth = Math.round((ele?.offsetWidth||0) - 0);
      // const adjustedWindowWidth = Math.round(window.innerWidth - 0)
      const cardSize = 118
      const maxCards = Math.floor(adjustedWindowWidth / cardSize);
     this.showArrows= (this.type && (this.productsList.length > maxCards)) ? true : false
    },
    goPrev() {
      this.$refs.splide.go("-1");
    },
    goNext() {
      this.$refs.splide.go("+1");
    },
    openProductModal(data) {
      this.setBannerImg(data.images[0]);
      this.showModal = true;
      this.activeData = { ...data }; // Use object spread to create a new reference
      // console.log(this.activeData);
    },

    getProductList() {
      this.recordsFetching=true;
      if(this.type!='related-products'){
    
        let url = "";

        if (!this.type && !this.categoryId && !this.isReadyToShip) {
          url = `buyer/products?page=${this.page}&pageSize=${this.pageSize}&sortBy=${this.sortBy}&sortOrder=${this.sortOrder}`;

          if (this.storeId) {
            url += `&store_id=${this.storeId}`;
          }

          if (this.$route.query?.searchText) {
            url += `&searchText=${this.$route.query?.searchText}`;
          }
          else{
            // this.isLoading = this.$loading.show();
          }
        } else if (this.categoryId) {
          url = `buyer/products?page=${this.page}&pageSize=${this.pageSize}&sortBy=${this.sortBy}&sortOrder=${this.sortOrder}&category_ids=${this.categoryId}`;

          if (this.storeId) {
            url += `&store_id=${this.storeId}`;
          }

          if (this.$route.query?.searchText) {
            url += `&searchText=${this.$route.query?.searchText}`;
          }
          else{
            // this.isLoading = this.$loading.show();
          }
        }
        else if(this.isReadyToShip){
          url = `buyer/products?page=${this.page}&pageSize=${this.pageSize}&sortBy=${this.sortBy}&sortOrder=${this.sortOrder}`;
          url = url + `&ready_to_ship=true`;
          // this.isLoading = this.$loading.show();
        } 
        else {
          url = `buyer/products/${this.type}?page=1&pageSize=10`;
          if (this.$route.query?.searchText) {
            url += `&searchText=${this.$route.query?.searchText}`;
          }
          else{
            // this.isLoading = this.$loading.show();
          }
        }
        if(this.selectedCountry){
            url += `&country=${this.selectedCountry}`;
          }
        _commonService
        .get(url)
        .then((response) => {
          if (response.data.status == 1) {
            // this.isLoading? this.isLoading.hide():true;
            this.productsList = response.data.results || [];
            if(this.selectedCurrency && this.selectedCurrency.currency && !this.selectedCurrency.is_base_currency){
              this.productsList.forEach((item)=>{
                if(item.price_type=='Single Pricing'){
                  item.price= item.price ? (item.price * this.selectedCurrency.rate_to_base) : 0;
                  item.compare_at= item.compare_at ? (item.compare_at * this.selectedCurrency.rate_to_base) : 0;
                  item.price=this.toDecimalPrice(item.price);
                  item.compare_at=this.toDecimalPrice(item.compare_at);
                }
                else if(item.price_type=='Multi Level Pricing'){
                  item.prices.forEach((level)=>{
                    level.price= level.price ? (level.price * this.selectedCurrency.rate_to_base) : 0;
                    level.compare_at= level.compare_at ? (level.compare_at * this.selectedCurrency.rate_to_base) : 0;
                    level.price=this.toDecimalPrice(level.price);
                    level.compare_at=this.toDecimalPrice(level.compare_at);
                  })
                }
              })
            }
            this.bindDefaultMultiplePrice();
            this.paginationObj = response.data.pagination || { items: [] };
            this.recordsFetching=false;
          } else {
            // this.isLoading? this.isLoading.hide():true;
            this.notyf.error(response.data.message);
            this.recordsFetching=false;
          }
        })
        .catch((error) => {
          console.log(error);
          // this.isLoading? this.isLoading.hide():true;
          this.notyf.error(error.message);
        });
      }
      else{
        this.productsList= this.items;
        this.recordsFetching=false;
        this.bindDefaultMultiplePrice();
      }
    },

    bindDefaultMultiplePrice(){
      this.productsList.forEach((item) => {
              if(item.price_type=='Multi Level Pricing'){
                if(item.prices){
                  let defaultone=item.prices ? item.prices.filter((level)=>level.min_quantity<=1) :[];
                  if(defaultone.length){
                    let default_price= defaultone.find((level)=>level.min_quantity<=1);
                    item.default_price=default_price ? default_price :{};
                  }
                  else{
                    let default_price=item.prices[0];
                    item.default_price=default_price ? default_price :{};
                  }
                }
                else{
                  item.default_price={};
                }
              }
        });
    },
    doPaginationWise(page) {
      this.page = page;
      this.getProductList();
    },
    setPageSize(e){
      this.page = 1;
      this.getProductList();
    },
    openProductDetails(event, item) {
      event.preventDefault();
      // this.productDetails = {};
      // this.selectedVariant = {};
      // this.qty = 1;
      // this.bannerImage = "";
      // this.nonAvailableOptions = [];
      // this.$router.push("/product-details/" + item._id);
      const href=`/product-details/${item._id}`
      window.open(href, "_blank");
    },
    toDecimalPrice(price){
      let formated_price = price ? Math.round((+price)*100)/100 : 0;
      formated_price = formated_price.toFixed(2);
      // console.log('formated_price',formated_price);
      return formated_price; 
    },
    setBannerImg(img) {
      this.bannerImage = img && img.url ? img : ``;
    },
  },
  
});
</script>

<template>
  <div id="sliderContainer">
    <section class="best__sell">
        <div class="row" v-if="!recordsFetching">
          <div class="col-xl-12">
            <div class="section__head d-md-flex justify-content-between">
              <template v-if="type && productsList && productsList.length">
                <div class="section__title d-inline-flex">
                  <h3 v-if="type == 'new-arrivals'"><b>New Arrivals</b></h3>
                  <h3 v-else-if="type == 'gifts'"><b>Gifts</b></h3>
                  <h3 v-else-if="type == 'trending-items'"> <b>Trending Items</b></h3>
                  <h3 v-else-if="type == 'sale-items'"><b>Items On Sale</b></h3>
                  <h3 v-else-if="type == 'related-products'"><b>You May Also Like</b></h3>
                </div>
              </template>
              <template v-if="type && productsList && productsList.length">
                <!-- <div class="d-inline-flex align-items-center float-end product__nav-tab" v-if="type && ((type!='related-products'  && productsList.length>5) || (type=='related-products' && productsList.length>10))"> -->
                <!-- <div class="d-inline-flex align-items-center float-end product__nav-tab" v-if="type && ((type!='related-products'  && productsList.length>5) || showRelatedItemsArrows)"> -->
                <div class="d-inline-flex align-items-center float-end product__nav-tab" v-if="type && showArrows">
                  <div v-if="productsList.length !== 0" class="d-flex align-items-center ml-20 slider-nav" >
                    <div class="-prev pointer" @click="goPrev">
                      <button >
                        <i class="fal fa-angle-left text-white"></i>
                      </button>
                    </div>
                    <div class="-next pointer" @click="goNext">
                      <button >
                        <i class="fal fa-angle-right text-white"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <BaseProductsView v-if="recordsFetching" :type="type" :recordsFetching="recordsFetching" />
        <!-- <div class="row" v-if="recordsFetching" >
          <div class="col-xl-12">
            <div class="tab-content">
              <div class="tab-pane fade show active" >
                <div class="product__slider">
                  <Splide
                    ref="splide"
                    :options="{
                      type: 'slide',
                      perPage: 1,
                      rewind: true,
                      autoWidth: true,
                      arrows: false,
                      pagination: false,
                    }"
                    aria-label="Products"
                    :class="!type ? 'product__grid' : ''"
                  >
                    <SplideSlide
                      v-for="(item, index) in 20"
                      :key="index"
                    >
                      <div class="product__item white-bg">
                        <div class="product__thumb p-relative card-bg">
                          <a class="w-img">
                            <div class="product_img"></div>
                          </a>
                          
                        </div>
                        <div class="product__content text-center dummy_list">
                          <div class="product_price">
                            <span class="price" ></span>
                            <span class="price" ></span>
                          </div>
                          <div class="product_name"></div>
                        </div>
                      </div>
                    </SplideSlide>
                  </Splide>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="row" v-if="productsList.length !== 0 && !recordsFetching" >
          <div class="col-xl-12">
            <div class="tab-content">
              <div class="tab-pane fade show active" >
                <div class="product__slider">
                  <Splide
                    ref="splide"
                    :options="{
                      type: 'slide',
                      perPage: 1,
                      rewind: true,
                      autoWidth: true,
                      arrows: false,
                      pagination: false,
                    }"
                    aria-label="Products"
                    :class="!type ? 'product__grid' : ''"
                  >
                    <SplideSlide
                      v-for="(item, index) in productsList"
                      :key="index"
                      @click="openProductDetails($event, item)"
                    >
                      <div class="product__item white-bg">
                        <div class="product__thumb p-relative card-bg">
                          <a class="w-img">
                            <div
                              class="product_img"
                              :style="{
                                'background-image':
                                  'url(&quot;' +
                                  baseUrl +
                                  item?.images[0]?.url +
                                  '&quot;)',
                              }"
                              v-if="
                                item?.images &&
                                item?.images.length &&
                                !item?.images[0]?.key
                              "
                            ></div>
                            <div
                              class="product_img"
                              :style="{
                                'background-image':
                                  'url(&quot;' +
                                  item?.images[0]?.url +
                                  '&quot;)',
                              }"
                              v-if="
                                item?.images &&
                                item?.images.length &&
                                item?.images[0]?.key
                              "
                            ></div>
                            <div  class="no-image-found" v-if="!item?.images || !item?.images.length" >
                              <img
                                src="@/assets/imgs/no-image.jpg"
                                alt=""
                                style="
                                  width: -webkit-fill-available;
                                "
                              />
                            </div>
                          </a>
                          <!-- <div class="product__action p-absolute">
                            <ul>
                              <li>
                                <a
                                  title="Quick View"
                                  @click.stop="openProductModal(item)"
                                  ><i class="fal fa-search"></i
                                ></a>
                              </li>
                            </ul>
                          </div> -->
                        </div>
                        <div class="product__content text-center">
                          <template v-if="item.price_type=='Single Pricing'">
                            <span class="price" v-if="(+item?.compare_at)">
                              <b>{{ currency }} {{ item?.compare_at }} </b> &nbsp;
                              <span class="price"><small><s>{{ currency }} {{ item?.price }} </s></small></span>
                            </span>
                            <span class="price" v-if="!(+item?.compare_at)">
                              <b>{{ currency }} {{ item?.price }} </b>
                            </span>
                          </template>
                          <template v-if="item.price_type=='Multi Level Pricing'">
                            <span class="price" v-if="(+item?.default_price?.compare_at)">
                              <b>{{ currency }} {{item?.default_price?.compare_at}} </b> &nbsp;
                                <span class="price"><small> <s>{{ currency }} {{item?.default_price?.price}} </s> </small></span>
                              </span>
                              <span class="price" v-if="!(+item?.default_price?.compare_at)">
                                <b> {{ currency }} {{item?.default_price?.price}} </b></span>
                          </template>
                          <h6 class="product-name">
                            <a class="product-item-link"> {{ item.title }}</a>
                          </h6>
                        </div>
                      </div>
                    </SplideSlide>
                  </Splide>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav aria-label="Page navigation example " v-if="paginationObj?.total > 20 && !this.type">
            <div class="row mt-3">
                <div class="col-md-6">
                  <div class="form-group mt-2 text-start">
                    <span class="float-left" style="width: 70px; margin-top: 6px; margin-right: 10px;">Page Size:</span>
                    <span class="float-left">
                      <select
                        class="form-select"
                        v-model="pageSize"
                        @change="setPageSize()"
                        style="width: 90px; display: inline-block"
                      >
                        <option value="">-Select-</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </span>
                    <span
                      class="float-left"
                      style="margin-top: 6px !important; margin-left: 16px !important"
                      >{{ paginationObj?.total }} Record(s) Found</span
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <ul class="pagination pagination-md justify-content-end mt-3">
                    <li
                      class="page-item"
                      v-bind:class="{ disabled: paginationObj?.previous_page == 0 }">
                      <a
                        class="page-link"
                        aria-label="Previous"
                        @click="doPaginationWise(paginationObj?.previous_page)"
                      >
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>
                    <li
                      class="page-item"
                      v-bind:class="{
                        active: pagination == paginationObj?.current_page,
                      }"
                      v-for="(pagination, index) in paginationObj?.items"
                      :key="index"
                    >
                      <a class="page-link" @click="doPaginationWise(pagination)">{{
                        pagination
                      }}</a>
                    </li>

                    <li
                      class="page-item"
                      v-bind:class="{ disabled: paginationObj?.next_page == 0 }"
                    >
                      <a
                        class="page-link"
                        aria-label="Next"
                        @click="doPaginationWise(paginationObj?.next_page)"
                      >
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </div>
            </div>
        </nav>
        <div class="text-center" v-if="productsList.length == 0 && !recordsFetching">
          <p style="font-size: 18px">No Product Found</p>
        </div>
        
    </section>
    <!-- shop modal start -->

    <div
      class="modal-overlay"
      @click="showModal = false"
      :class="{ show: showModal }"
    ></div>

    <div
      class="modal fade"
      :class="showModal ? 'show' : ''"
      :style="showModal ? 'display: block !important;' : ''"
    >
      <div
        class="modal-dialog modal-dialog-centered product__modal"
        role="document"
      >
        <div class="modal-content">
          <div class="product__modal-wrapper p-relative">
            <div class="product__modal-close p-absolute">
              <button
                data-bs-dismiss="modal"
                @click="showModal = false"
              >
                <i class="fal fa-times"></i>
              </button>
            </div>
            <div class="product__modal-inner">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <div class="product__details-nav">
                    <div class="product__details-thumb">
                      <div class="product__details-nav-thumb">
                        <img
                          :src="baseUrl + bannerImage.url"
                          alt=""
                          class="w-100"
                          v-if="bannerImage.url && !bannerImage?.key"
                        />
                        <img
                          :src="bannerImage.url"
                          alt=""
                          class="w-100"
                          v-if="bannerImage.url && bannerImage?.key"
                        />
                        <img
                          src="../assets/imgs/no-image.jpg"
                          alt=""
                          class="w-100"
                          v-if="!bannerImage.url"
                        />
                      </div>
                    </div>
                    <div class="d-sm-flex align-items-start">
                      <ul
                        class="nav nav-tabs d-sm-flex align-items-start"
                        id="productThumbTab"
                        role="tablist"
                      >
                        <li
                          v-for="(item, index) in activeData.images"
                          :key="index"
                          class="nav-item"
                          role="presentation"
                          @click="setBannerImg(item)"
                        >
                          <button
                            class="nav-link"
                            type="button"
                          >
                            <img
                              :src="baseUrl + item.url"
                              alt=""
                              v-if="item.url && !item?.key"
                            />
                            <img
                              :src="item.url"
                              alt=""
                              v-if="item.url && item?.key"
                            />
                            <img
                              src="../assets/imgs/no-image.jpg"
                              alt=""
                              v-if="!item.url"
                            />
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div
                  v-if="activeData"
                  class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
                >
                  <div class="product__modal-content">
                    <h4>
                      <a>{{ activeData?.title }}</a>
                    </h4>
                    <div class="product__modal-des mb-40">
                      <p v-html="activeData?.description"></p>
                    </div>
                    <div class="product__stock">
                      <span>Availability :</span>
                      <span>{{
                        activeData.status === "Active"
                          ? "In Stock"
                          : "Out of Stock"
                      }}</span>
                    </div>
                    <div class="product__stock sku mb-30">
                      <span>SKU:</span>
                      <span>{{ activeData?.title }}</span>
                    </div>

                    <div class="product__price">
                      <!-- <span v-if="activeData?.compare_at">
                        <b>{{ activeData?.compare_at }} {{ currency }} </b> &nbsp;
                        <span
                          ><small>
                            <s>{{ activeData?.price }} {{ currency }}</s>
                          </small></span
                        >
                      </span>
                      <span v-if="!activeData?.compare_at">
                       <b> {{ activeData?.price }} {{ currency }}</b>
                      </span> -->
                      <template v-if="activeData.price_type=='Single Pricing'">
                            <span class="price" v-if="activeData?.compare_at">
                              <b>{{ currency }} {{ activeData?.compare_at }} </b> &nbsp;
                              <span class="price"><small><s>{{ currency }} {{ activeData?.price }} </s></small></span>
                            </span>
                            <span class="price" v-if="!activeData?.compare_at">
                              <b> {{ currency }} {{ activeData?.price }} </b>
                            </span>
                      </template>
                      <template v-if="activeData.price_type=='Multi Level Pricing'">
                            <span class="price" v-if="activeData?.default_price?.compare_at">
                              <b>{{ currency }} {{activeData?.default_price?.compare_at}} </b> &nbsp;
                                <span class="price"><small> <s>{{ currency }} {{activeData?.default_price?.price}} </s> </small></span>
                              </span>
                              <span class="price" v-if="!activeData?.default_price?.compare_at">
                                <b>{{ currency }} {{activeData?.default_price?.price}} </b></span>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- shop modal end -->
  </div>
</template>
<style scoped lang="scss">

.product-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
}

//product detials

.product-img-block-mobile {
  display: none;
}
.image-grid {
  background: #ffffff;
}
.product-image {
  width: auto;
  height: 325px; // 440px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  // border: 1px solid #f3f3f4;
  border-radius: 12px;
}

.sub-images {
  ul {
    display: flex;

    li {
      display: flex;
      list-style-type: none;
      margin: 7px;
      justify-content: center;
      cursor: pointer;
    }
  }

  ul.d-flex-column {
    flex-direction: column;
    // justify-content: center;
    height: 440px;
    overflow: auto;
    padding: 0px 0;
    img {
      height: 60px;
      // border: 1px solid #f3f3f4;
      width: auto;
      border-radius: 8px;
    }
  }

  ul.d-flex-row {
    flex-direction: row;
    // justify-content: center;
    padding: 10px 0 0;
    overflow-x: auto;
    img {
      height: 50px;
      // border: 1px solid #f3f3f4;
      width: auto;
      border-radius: 4px;
    }
  }
}
.selected-image {
  border: 4px solid #d63547;
}
.border-default {
  border: 3px solid #d63547;
}
.variants-img {
  img {
    height: 40px;
    width: auto;
    border: 1px solid #dee2e6;
    border-radius: 6px;
  }
}
.no-image-found{
  height: 118px;
  img{
    height: 100%;
    width: auto;
  };
}
@media (max-width: 680px) {
  // .product-img-block{
  //   display: none;
  // }
  // .product-img-block-mobile{
  //   display: block;

  //   .ribbon-top-left span{
  //     left: -60px;
  //     top: 14px;
  //   }

  //   .ribbon span{
  //     width: 180px;
  //   }

  // }
  .product-info {
    padding-top: 10px;
  }

  .swiper-slide .item {
    width: 200px !important;
    margin: auto !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 12px;
  }
  .product-image {
    height: 245px;
  }
  .sub-images ul.d-flex-row img {
    height: 50px;
  }
}
.add_del {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
  .btn_div {
    height: 28px;
    width: 28px;
    border-radius: 100%;
    position: relative;
    background: #d63547;
    .material-icons {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 18px !important;
    }
  }
  .btn_div_disabled {
    height: 28px;
    width: 28px;
    border-radius: 100%;
    position: relative;
    background: #bababd;
    pointer-events: none;
    .material-icons {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 18px !important;
    }
  }

  label {
    margin-left: 10px;
    margin-right: 10px;
  }
}



</style>
