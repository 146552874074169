<template>
  <div
    class="nice-select"
    tabindex="0"
    :class="isOpen ? 'open' : ''"
    @click="toggleList"
  >
    <span class="current">{{ getLabel(selectedOption) }}</span>
    <ul
      class="list"
      v-show="isOpen"
    >
      <li
        class="option"
        :class="[
          {
            selected: selectedOption == '',
            focus: selectedOption == '',
          },
        ]"
        @click.stop="selectOption('')"
      >
        All Categories
      </li>
      <li
        v-for="option in options"
        :key="option._id"
        :data-value="option.name"
        :class="[
          'option',
          {
            selected: option._id === selectedOption,
            focus: option._id === selectedOption,
          },
        ]"
        @click.stop="selectOption(option._id)"
      >
        {{ option.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    selectedOption: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleList() {
      this.isOpen = !this.isOpen;
    },
    selectOption(option) {
      this.$emit("change", option);
      this.isOpen = false;
    },
    getLabel(id) {
      console.log(id);
      if (id == "") {
        return "All Categories";
      }
      const option = this.options.find((option) => option._id === id);
      return option ? option.name : "";
    },
  },
};
</script>
