<template>
  <!-- features area start -->

  <div class="sm:flex w-full justify-center overflow-x-auto">
    <div class="">
      <div class="features__item d-flex white-bg">
        <div class="features__icon mr-15">
          <i class="fal fa-rocket-launch"></i>
        </div>
        <div class="features__content">
          <h6>Free Shipping</h6>
          <p class="truncate">Free Shipping On All Order</p>
        </div>
      </div>
    </div>
    <div class="">
      <div class="features__item d-flex white-bg">
        <div class="features__icon mr-15">
          <i class="fal fa-sync"></i>
        </div>
        <div class="features__content">
          <h6>Money Guarantee</h6>
          <p class="truncate">30 Day Money Back Guarantee</p>
        </div>
      </div>
    </div>
    <div class="">
      <div class="features__item d-flex white-bg">
        <div class="features__icon mr-15">
          <i class="fal fa-user-headset"></i>
        </div>
        <div class="features__content">
          <h6>Online Support 24/7</h6>
          <p class="truncate">Technical Support Stand By</p>
        </div>
      </div>
    </div>
    <div class="">
      <div class="features__item features__item-last d-flex white-bg">
        <div class="features__icon mr-15">
          <i class="fal fa-calendar"></i>
        </div>
        <div class="features__content">
          <h6>Cheap Price</h6>
          <p class="truncate">Lowest Prices In 90 days</p>
        </div>
      </div>
    </div>
    <div class="">
      <div class="features__item features__item-last d-flex white-bg">
        <div class="features__icon mr-15">
          <i class="fal fa-clock"></i>
        </div>
        <div class="features__content">
          <h6>Delivery Guarantee</h6>
          <p class="truncate">On-time delivery Guarantee</p>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
</style>
