<template>
  <li
    class="relative"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
    @click="$emit('all',$event)"
  >
    <div class="block cursor-pointer pt-2 pb-1 text-white"
    :class="(selectedPage && selectedPage.toLowerCase() == 'products') ? 'selected' : ''"
    >
      <span class="text-base font-medium titlecase"
        >{{name}}</span
      >
      <i class="fas fa-angle-down text-base font-normal ml-2" v-if="categories.length"></i>
    </div>
    <ul v-show="isHovered" class="absolute z-50 left-0  bg-white px-0 py-2.5 parent-list">
      <SubDropdown
        v-for="(category, index) in categories"
        :key="index"
        :item="category"
        :index="index"
        @open="$emit('open', $event)"
      />
    </ul>
  </li>
</template>

<script>
import SubDropdown from "./SubDropdown.vue";
export default {
  name: "Dropdown",
  components: { SubDropdown },
  props: {
    categories: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      default: "Categories",
    },
    selectedPage: {},
  },
  data() {
    return {
      isHovered: false,
    };
  },
  computed: {
    hasSubcategory() {
      return this.item.subcategory && this.item.subcategory.length;
    },
  },
};
</script>

<style scoped lang="scss">
ul.parent-list{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: max-content;
  height: max-content;
  /* min-height: 350px; */
  max-height: max-content;
}
ul.parent-list li{
  display: block !important;
  width: 100% !important;
}
.selected {
    border-bottom: 2px solid white;
}
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
</style>
