<template>
  <div
    class="cart__mini"
    :class="active ? 'cart__opened' : ''"
  >
    <div class="cart__close">
      <button
        type="button"
        class="cart__close-btn"
        @click="$emit('cancel')"
      >
        <i class="fal fa-times"></i>
      </button>
    </div>
    <ul>
      <li>
        <div class="cart__title">
          <h4
            class="clickable"
            @click="$router.push('/store-details/' + storeId)"
          >
            {{ store_name ? store_name : "Cart" }}
          </h4>
          <span>({{ cartListObject.products.length }} Item in Cart)</span>
        </div>
      </li>
      <li>
        <div
          v-for="(product, index) in cartListObject.products"
          :key="index"
          class="cart__item"
        >
          <div
            class="cart__item d-flex justify-content-between align-items-center"
          >
            <div class="cart__inner d-flex">
              <div class="cart__thumb">
                <img
                  src="../assets/imgs/no-image.jpg"
                  alt=""
                  v-if="!product?.image && !product?.product_id?.images?.length"
                />
                <img
                  :src="baseUrl + product?.product_id?.images[0]?.url"
                  alt="test"
                  v-if="
                    !product?.image &&
                    product?.product_id?.images &&
                    product?.product_id?.images.length &&
                    !product?.product_id?.images[0]?.key
                  "
                />
                <img
                  :src="product?.product_id?.images[0]?.url"
                  alt="test"
                  v-if="
                    !product?.image &&
                    product?.product_id?.images &&
                    product?.product_id?.images.length &&
                    product?.product_id?.images[0]?.key
                  "
                />
                <img
                  :src="baseUrl + product?.image.url"
                  alt="test"
                  v-if="product?.image && !product?.image.key"
                />
                <img
                  :src="product?.image.url"
                  alt="test"
                  v-if="product?.image && product?.image.key"
                />
              </div>
              <div class="cart__details">
                <h6>
                  <a>
                    {{ product.title }}
                    <span
                      v-if="product.is_sample"
                      class="text-dark"
                      style="font-size: 15px"
                      >(Sample)</span
                    >
                  </a>
                </h6>

                <template v-if="product?.variant_id && product?.options">
                  (<span
                    v-for="(opt, i) in product?.options"
                    :key="i"
                  >
                    <span>
                      {{ opt?.name }} : {{ opt?.value ? opt.value : ""
                      }}<span v-if="i + 1 != product?.options.length"
                        >,</span
                      ></span
                    >
                  </span>
                  )
                </template>
                <div class="cart__price">
                  <span>{{ currency }} {{ product?.price }} </span>
                </div>
                <div class="product-quantity mr-20 mb-25">
                  <div class="cart-plus-minus p-relative">
                    <input
                      type="text"
                      readonly=""
                      v-model="product.quantity"
                    />
                    <div
                      class="dec qtybutton"
                      :class="
                        (!product?.available && !product?.availableqty) ||
                        product.error
                          ? 'btn_div_disabled'
                          : 'btn_div'
                      "
                      @click="minus(product)"
                    >
                      -
                    </div>
                    <div
                      :class="
                        (!product?.available && !product?.availableqty) ||
                        product.error
                          ? 'btn_div_disabled'
                          : 'btn_div'
                      "
                      class="inc qtybutton"
                      @click="plus(product)"
                    >
                      +
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="cart__del"
              @click="deleteItem(product, index, cartListObject.products)"
            >
              <a><i class="fal fa-trash-alt"></i></a>
            </div>
          </div>
          <div
            class="error-massage"
            v-if="product?.error"
          >
            <p class="mb-1">{{ product.error }}</p>
          </div>
          <div
            class="d-block justify-content-between"
            v-if="product?.product_id?.shipping_cost"
          >
            <template
              v-if="product?.product_id?.shipping_cost == 'Contact Supplier'"
            >
              <div class="d-flex">
                <div class="d-flex"><small>Shipping : </small></div>
                <div class="d-flex"><b> Contact Supplier</b></div>
              </div>
            </template>
            <template v-if="product?.product_id?.shipping_cost == 'Fixed Cost'">
              <div class="d-block text-start">
                <small>Select Shipping Method</small>
              </div>
              <div class="d-block p-1 text-start">
                <table class="table mb-0">
                  <tbody>
                    <tr
                      :class="
                        product?.shippingMethod == item?.method
                          ? 'selected-tr'
                          : 'default-tr'
                      "
                      v-for="(item, index) in product?.product_id?.fixed_costs"
                      :key="index"
                    >
                      <td class="border-0">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            v-model="product.shippingMethod"
                            type="radio"
                            @change="onShippingMethodChange($event)"
                            name="fixed_costs"
                            :id="'p' + index"
                            :value="item.method"
                          />
                          <label
                            class="form-check-label"
                            :for="'p' + index"
                          >
                            {{ item.method }}
                          </label>
                        </div>
                      </td>
                      <td class="border-0">
                        <label
                          class="form-check-label"
                          :for="'p' + index"
                        >
                          {{ item?.estimated_min_days }} -
                          {{ item?.estimated_max_days }} Days</label
                        >
                      </td>
                      <td class="border-0">
                        <label
                          class="form-check-label"
                          :for="'p' + index"
                        >
                          <span
                            v-if="
                              !product?.shippingMethod ||
                              product?.shippingMethod != item.method
                            "
                            >{{ currency }} {{ item.price || 0 }} </span
                          >
                          <span v-if="product?.shippingMethod == item?.method"
                            ><b>{{ currency }} {{ item.price || 0 }} </b></span
                          >
                        </label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </div>
        </div>
      </li>
      <li>
        <div class="">
          <div
            class="cart__sub d-flex justify-content-between align-items-center"
          >
            <h6>Item(s) total</h6>
            <span class="cart__sub-total">{{ currency }} {{ subTotal }} </span>
          </div>
          <div
            class="cart__sub d-flex justify-content-between align-items-center"
            v-if="shippingCharges"
          >
            <h6>Shipping Charges total</h6>
            <span class="cart__sub-total"
              >{{ currency }} {{ shippingCharges }} </span
            >
          </div>
          <!-- <div>
            <hr style="border-top: 1px dashed black" />
          </div> -->
          <div
            class="cart__sub d-flex justify-content-between align-items-center"
          >
            <h6>Order Total</h6>
            <span class="cart__sub-total">{{ currency }} {{ grandTotal }} </span>
          </div>
        </div>
      </li>
      <li>
        {{ cartListObject.product }}
        <a
          v-if="
            cartListObject?.products.length &&
            cartListObject?.sample_products.length
          "
          @click="productCheckout"
          class="t-y-btn w-100 mb-10"
          >Proceed to checkout</a
        >
        <router-link
          to="/cart"
          class="t-y-btn t-y-btn-border w-100 mb-10"
          >view add edit cart</router-link
        >
      </li>
    </ul>
    <div
      class="modal-overlay"
      :class="{ show: deleteModal }"
    ></div>

    <div
      class="modal fade"
      :class="deleteModal ? 'show' : ''"
      :style="deleteModal ? 'display: block !important;' : ''"
    >
      <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              id="confirmDeleteLabel"
            >
              Confirm
            </h5>
            <button
              type="button"
              class="btn-close"
              aria-label="Close"
              @click="deleteModal = false"
            ></button>
          </div>
          <div class="modal-body text-start">
            <div class="text-black">
              Do you want to remove this item from cart ?
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="deleteModal = false"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-default"
              @click="
                deleteFromCart(deleteItemObj.product, deleteItemObj.index)
              "
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import _commonService from "../services/common_service";
import { API_ENDPOINT, BASE_URL } from "../../environment";
import HeaderBar from "./HeaderBar.vue";

export default defineComponent({
  name: "CartView",
  components: { HeaderBar },
  inject: ["notyf"],
  props: {
    active: {
      type: Boolean,
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  data() {
    return {
      dismissibleDuration:10000,
      isLoading: false,
      itemsLoaded: false,
      // cartList:[],
      allCartItems: [],
      cartListObject: {
        products: [],
        sample_products: [],
      },
      shippingCharges: 0,
      deleteModal: false,
      page: 1,
      pageSize: 30,
      sortBy: "_created",
      sortOrder: "DESC",
      baseUrl: BASE_URL,
      store_name: "",
      storeId: "",

      grandTotal: 0,
      subTotal: 0,
      deleteItemObj: {},
      productDetails: {},
    };
  },
  watch: {
    cardId: {
      handler() {
        console.log("event bus");
        this.getCartList();
      },
    },
  },
  computed: {
    cardId() {
      return this.$store.getters.getCardId;
    },
  },
  created() {
    let token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : null;
    if (token) {
      this.getCartList();
    } else {
      this.notyf.error("Please Login");
    }
  },
  methods: {
    getCartList() {
      this.isLoading = this.$loading.show();
      const url = `buyer/cart`;
      _commonService
        .get(url)
        .then((response) => {
          if (response.data.status == 1) {
            this.isLoading.hide();
            this.allCartItems = response.data.results || [];
            this.itemsLoaded = true;
            if (this.allCartItems.length) {
              this.store_name = this.allCartItems[0].store_id.business_name;
              this.storeId = this.allCartItems[0].store_id._id;
            }
            let products = this.allCartItems.filter((item) => !item.is_sample);
            let allSampleProducts = this.allCartItems.filter(
              (item) => item.is_sample
            );
            this.cartListObject.products = products;
            this.cartListObject.sample_products = [];
            let pIds = allSampleProducts.map((item) => item.product_id._id);
            let uniqueProducts = pIds.filter(
              (item, i, ar) => ar.indexOf(item) === i
            );
            uniqueProducts.forEach((item) => {
              let obj = { id: item, samples: [], samplesShippingMethod: "" };
              obj.samples = allSampleProducts.filter(
                (ele) => ele.product_id._id == item
              );
              obj.sample_shipping_cost = obj.samples.length
                ? obj.samples[0].product_id.sample_shipping_cost
                : "";
              obj.sample_fixed_costs = obj.samples.length
                ? obj.samples[0].product_id.sample_fixed_costs
                : [];
              obj.minimum_order = obj.samples.length
                ? obj.samples[0].product_id.minimum_order
                : 1;
              obj.maximum_order = obj.samples.length
                ? obj.samples[0].product_id.maximum_order
                : "";
              this.cartListObject.sample_products.push(obj);
            });

            this.cartListObject.products.forEach((item) => {
              item.available = true;
              item.shippingMethod = "";
              if (item?.product_id?.ready_to_ship) {
                if (item.quantity > item?.product_id?.maximum_per_order) {
                  item.error = `Maximum ${item?.product_id?.maximum_per_order} item(s) can be ordered for this product. Please remove extra item(s)`;
                }
              } else {
                item.error =
                  "Please remove item from cart. Item is not ready to ship yet.";
              }
            });

            this.cartListObject.sample_products.forEach((item) => {
              item.samples.forEach((s) => {
                if (!s?.product_id?.ready_to_ship) {
                  s.error =
                    "Please remove item from cart. Item is not ready to ship yet.";
                }
              });

              item.samples.length
                ? item.samples.forEach((current) => {
                    current.available = true;
                  })
                : true;
              let totalQty = item.samples.reduce(
                (total, current) => total + current.quantity,
                0
              );
              let maximumOrder = item.samples.length
                ? item.samples[0].product_id?.maximum_order
                : 0;
              if (totalQty == maximumOrder) {
                item.samples.forEach((ele) => {
                  ele.availableqty = false;
                });
              } else {
                item.samples.forEach((ele) => {
                  ele.availableqty = true;
                });
              }
            });

            // cartList.forEach((item)=>{
            //   item.available=true;
            //   if(!item.is_sample){
            //     if(item?.product_id?.ready_to_ship){
            //         if(item.quantity > item?.product_id?.maximum_per_order){
            //           item.error=  `Maximum ${item?.product_id?.maximum_per_order} item(s) can be ordered for this product. Please remove extra item(s)`
            //       }

            //     }
            //     else{
            //       item.error='Please remove item from cart. Item is not ready to ship yet.'
            //     }
            //   }
            //   else{
            //       if(item.quantity== item?.product_id?.maximum_order){
            //           item.availableqty=false;
            //       }
            //   }

            // });
            this.calculateSubTotal();
          } else {
            this.isLoading.hide();
            this.notyf.error(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading.hide();
          this.notyf.error(error.message);
        });
    },
    deleteItem(product, index, array) {
      this.deleteModal = true;
      console.log(product);
      this.deleteItemObj = {
        product: product,
        index: index,
        records: array,
      };
    },
    deleteFromCart(item, index) {
      if (item && item._id) {
        this.isLoading = this.$loading.show();
        const url = `buyer/cart/${item._id}`;
        _commonService
          .delete(url)
          .then(async (response) => {
            this.deleteModal = false;
            this.isLoading.hide();
            if (response.data.status == 1) {
              let ele = document.getElementById("cmodalCloseBtn");
              if (ele) ele.click();
              this.notyf.success(response.data.message);
              this.allCartItems = this.allCartItems.filter(
                (ele) => ele._id != item._id
              );
              this.deleteItemObj.records.splice(index, 1);
              this.$store.dispatch("updateCart", this.allCartItems);
              this.$store.dispatch("triggerCart");
              this.calculateSubTotal();
            } else {
              this.isLoading.hide();
              this.notyf.error(response.data.message);
            }
          })
          .catch((error) => {
            this.deleteModal = false;
            this.isLoading.hide();
            console.log("error", error);
            this.notyf.error(error.message);
          });
      }
    },
    async plus(obj) {
      if (
        obj?.product_id?.maximum_per_order &&
        obj.quantity == obj?.product_id?.maximum_per_order
      ) {
        this.notyf.error(
          `Maximum ${obj?.product_id?.maximum_per_order} item(s) can be ordered for this product.`
        );
        return;
      }
      if (obj.variant_id) {
        let variantdata = obj.variant_id; //= await this.isVariantAvailable(productdata,obj);
        if (variantdata) {
          // LOGIC TO HANDLE VARIANT PRICES
          if (variantdata.price_type == "Multi Level Pricing") {
            if (variantdata.prices) {
              const newQty = obj.quantity + 1;
              let defaultone = variantdata.prices
                ? variantdata.prices.find(
                    (level) =>
                      newQty >= level.min_quantity &&
                      newQty <= level.max_quantity
                  )
                : null;
              if (defaultone) {
                obj.price = defaultone.compare_at
                  ? defaultone.compare_at
                  : defaultone.price;
                obj.quantity = obj.quantity + 1;
                this.updateCart(obj);
              } else {
                this.notyf.error({message:"Price not available for this quantity. Please contact to seller",dismissible:true,icon:false,duration:this.dismissibleDuration});
                return;
              }
            } else {
              this.notyf.error(
                {message:"Price not available for this quantity. Please contact to seller",dismissible:true,icon:false,duration:this.dismissibleDuration});
              return;
            }
          } else {
            obj.quantity = obj.quantity + 1;
            this.updateCart(obj);
          }
        } else {
          this.notyf.error({message:"Sorry variant is not available",dismissible:true,duration:this.dismissibleDuration});
        }
      } else {
        let productDetails = obj.product_id; //await this.getProductDetails(obj);
        if (productDetails.price_type == "Multi Level Pricing") {
          if (productDetails.prices) {
            const newQty = obj.quantity + 1;
            let defaultone = productDetails.prices
              ? productDetails.prices.find(
                  (level) =>
                    newQty >= level.min_quantity && newQty <= level.max_quantity
                )
              : null;
            if (defaultone) {
              obj.price = defaultone.compare_at
                ? defaultone.compare_at
                : defaultone.price;
              obj.quantity = obj.quantity + 1;
              this.updateCart(obj);
            } else {
              this.notyf.error(
                {message:"Price not available for this quantity. Please contact to seller",dismissible:true,icon:false,duration:this.dismissibleDuration});
              return;
            }
          } else {
            this.notyf.error(
              {message:"Price not available for this quantity. Please contact to seller",dismissible:true,icon:false,duration:this.dismissibleDuration});
            return;
          }
        } else {
          obj.quantity = obj.quantity + 1;
          this.updateCart(obj);
        }
      }
      this.$store.dispatch("updateCart", this.allCartItems);
    },
    async minus(obj) {
      if (obj.quantity > 1) {
        if (obj.variant_id) {
          let variantdata = obj.variant_id; //= await this.isVariantAvailable(productdata,obj);
          if (variantdata) {
            // LOGIC TO HANDLE VARIANT PRICES
            if (variantdata.price_type == "Multi Level Pricing") {
              if (variantdata.prices) {
                const newQty = obj.quantity - 1;
                let defaultone = variantdata.prices
                  ? variantdata.prices.find(
                      (level) =>
                        newQty >= level.min_quantity &&
                        newQty <= level.max_quantity
                    )
                  : null;
                if (defaultone) {
                  obj.price = defaultone.compare_at
                    ? defaultone.compare_at
                    : defaultone.price;
                  obj.quantity = obj.quantity - 1;
                  this.updateCart(obj);
                } else {
                  this.notyf.error(
                    {message:"Price not available for this quantity. Please contact to seller",dismissible:true,icon:false,duration:this.dismissibleDuration});
                  return;
                }
              } else {
                this.notyf.error(
                  {message:"Price not available for this quantity. Please contact to seller",dismissible:true,icon:false,duration:this.dismissibleDuration});
                return;
              }
            } else {
              obj.quantity = obj.quantity - 1;
              this.updateCart(obj);
            }
          } else {
            this.notyf.error({message:"Sorry variant is not available",dismissible:true,duration:this.dismissibleDuration});
          }
        } else {
          let productDetails = obj.product_id; //await this.getProductDetails(obj);
          if (productDetails.price_type == "Multi Level Pricing") {
            obj.quantity = obj.quantity - 1;
            if (productDetails.prices) {
              let defaultone = productDetails.prices
                ? productDetails.prices.find(
                    (level) =>
                      obj.quantity >= level.min_quantity &&
                      obj.quantity <= level.max_quantity
                  )
                : null;
              if (defaultone) {
                obj.price = defaultone.compare_at
                  ? defaultone.compare_at
                  : defaultone.price;
                this.updateCart(obj);
              } else {
                this.notyf.error(
                  {message:"Price not available for this quantity. Please contact to seller",dismissible:true,icon:false,duration:this.dismissibleDuration});
                return;
              }
            } else {
              this.notyf.error(
                {message:"Price not available for this quantity. Please contact to seller",dismissible:true,icon:false,duration:this.dismissibleDuration});
              return;
            }
          } else {
            obj.quantity = obj.quantity - 1;
            this.updateCart(obj);
          }
        }

        if (obj?.product_id?.ready_to_ship) {
          if (obj.quantity <= obj?.product_id?.maximum_per_order) {
            obj.error = ``;
          }
        }
        this.$store.dispatch("updateCart", this.allCartItems);
      }
    },

    getProductDetails(item) {
      return new Promise((resolve, reject) => {
        const url = `buyer/products/${item.product_id}`;
        _commonService
          .get(url)
          .then(async (response) => {
            if (response.data.status == 1) {
              let productDetails = response.data.result || {};
              this.isLoading = false;
              resolve(productDetails);
            } else {
              reject(null);
              this.isLoading = false;
              this.notyf.error(response.data.message);
            }
          })
          .catch((error) => {
            reject(null);
            console.log(error);
            this.isLoading = false;
            this.notyf.error(error.message);
          });
      });
    },
    updateCart(obj) {
      this.isLoading = true;
      const url = `buyer/cart/${obj._id}`;
      let data = {
        quantity: obj.quantity,
        price: obj.price,
        // product_id: obj.product_id._id,
        // total_weight: (obj.quantity*obj.weight),
        // title: obj.title,
        // product_img: obj.product_img,
      };
      // console.log('item',obj,data);
      _commonService
        .put(url, data)
        .then((response) => {
          if (response.data.status == 1) {
            this.calculateSubTotal();
            this.notyf.success(response.data.message);
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.notyf.error(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.notyf.error(error.message);
        });
    },
    onShippingMethodChange(e) {
      this.calculateSubTotal();
    },
    calculateSubTotal() {
      this.subTotal = 0;
      this.shippingCharges = 0;
      // if (this.cartList) {
      //   this.cartList.forEach(element => {
      //     this.subTotal = this.subTotal + (element.price * element.quantity);
      //     // this.totalItemsWeight += element.weight ? (element.weight * element.quantity) : 0;
      //     if(element.is_sample){
      //       if(element.quantity== element?.product_id?.maximum_order){
      //           element.availableqty=false;
      //       }
      //       else{
      //          element.availableqty=true;
      //       }
      //     }
      //   });
      // }
      // this.grandTotal = this.subTotal;

      this.cartListObject.products.forEach((element) => {
        this.subTotal = this.subTotal + element.price * element.quantity;
        if (
          element?.product_id?.shipping_cost == "Fixed Cost" &&
          element?.shippingMethod
        ) {
          const selectedMethod = element?.product_id?.fixed_costs
            ? element?.product_id?.fixed_costs.find(
                (item) => item.method == element?.shippingMethod
              )
            : null;
          this.shippingCharges =
            this.shippingCharges +
            (selectedMethod ? selectedMethod.price || 0 : 0);
        }
      });

      this.cartListObject.sample_products.forEach((sample) => {
        sample.samples.forEach((element) => {
          this.subTotal = this.subTotal + element.price * element.quantity;
        });

        if (
          sample?.sample_shipping_cost == "Fixed Cost" &&
          sample?.samplesShippingMethod
        ) {
          const selectedMethod = sample?.sample_fixed_costs
            ? sample?.sample_fixed_costs.find(
                (item) => item.method == sample?.samplesShippingMethod
              )
            : null;
          this.shippingCharges =
            this.shippingCharges +
            (selectedMethod ? selectedMethod.price || 0 : 0);
        }
        let totalQty = sample.samples.reduce(
          (total, current) => total + current.quantity,
          0
        );
        let maximumOrder = sample.maximum_order ? sample.maximum_order : 0;
        if (totalQty == maximumOrder) {
          sample.samples.forEach((ele) => {
            ele.availableqty = false;
          });
        } else {
          sample.samples.forEach((ele) => {
            ele.availableqty = true;
          });
        }
      });

      this.grandTotal = this.subTotal + this.shippingCharges;
    },
    productCheckout() {
      var url = `buyer/products-checkout`;
      var data = {
        cart_ids: this.allCartItems.map((item) => item._id),
        final_payment_amount: this.grandTotal,
        store_id: this.storeId,
        // subtotal:this.subTotal,
        // shipping_price:this.totalShippingPrice,
        // total_weight:this.totalItemsWeight,
      };
      console.log("data", data);
      _commonService
        .post(url, data)
        .then((response) => {
          if (response.data.status == 1) {
            this.notyf.success(response.data.message);
            this.$router.push("/checkout/" + response.data.result._id);
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.notyf.error(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.notyf.error(error.message);
        });
    },
  },
});
</script>
