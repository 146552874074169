<script>
import Multiselect from "@vueform/multiselect";
import { defineComponent, nextTick, onMounted } from "vue";
import _commonService from "../services/common_service";
import { API_ENDPOINT, BASE_URL } from "../../environment";
import CurrencyComponent from "./CurrencyComponent.vue";
import CountryComponent from "./CountryComponent.vue";
import LoginView from "./LoginView.vue";
import RegisterView from "./RegisterView.vue";
import ForgotPasswordView from "./ForgotPasswordView.vue";
import CategoryComponent from "./CategoryComponent.vue";
import NiceSelect from "./NiceSelect.vue";
import MyCart from "./MyCart.vue";
import Dropdown from "./Dropdown.vue";
import MainSlider from "./MainSlider.vue";
import {
  loginModal,
  registerModal,
  forgetPasswordModal,
  openLoginModal,
  logoutConformationModal,
  openLogoutModal,
  closeLogoutModal,
} from "../state/authState";
export default defineComponent({
  name: "HeaderBar",
  // props: {selectedPage: String,},
  components: {
    CountryComponent,
    MainSlider,
    LoginView,
    RegisterView,
    ForgotPasswordView,
    CategoryComponent,
    NiceSelect,
    Dropdown,
    MyCart,
    CurrencyComponent,
    Multiselect,
  },
  inject: ["notyf"],
  computed: {
    user() {
      return this.$store.state.user;
    },
    navItems() {
      return [
        {
          id: 1,
          name: "Home",

          page: "home",
          action: () => this.$router.push("/"),
          subcategory: [],
          condition: true, // Always visible
        },
        {
          id: 2,
          name: "Categories",
          open: true,
          page: "categories",
          action: () => {
            this.showCurrencies = false;
          }, // no action for categories as per your provided code
          subcategory: this.rootCategoryList,
          condition: true, // Always visible
        },
        {
          id: 3,
          name: "Currency",
          open: true,
          page: "currencies",
          action: () => {}, // no action for categories as per your provided code
          subcategory: [],
          condition: true, // Always visible
        },
        {
          id: 3,
          name: "Country",
          open: true,
          page: "country",
          action: () => {}, // no action for categories as per your provided code
          subcategory: [],
          condition: true, // Always visible
        },
        {
          id: 4,
          name: "Sellers",

          page: "seller",
          action: () => this.$router.push("/sellers"),
          subcategory: [],
          condition: true, // Always visible
        },
        {
          id: 5,
          name: "Contact",

          page: "contact",
          action: () => this.$router.push("/contact"),
          subcategory: [],
          condition: true, // Always visible
        },
        {
          id: 5,
          name: "My Account",

          page: "account",
          action: () => this.$router.push("/profile/my-profile"),
          subcategory: [],
          condition: true, // Always visible
        },
        {
          id: 6,
          name: "Cart",

          page: "cart",
          action: () => this.$router.push("/cart"),
          subcategory: [],
          condition: this.$store.getters.isAuthenticated, // Visible if cart is not empty and selected page is not cart
        },
        // {
        //   id: 6,
        //   name: "Checkout",

        //   page: "checkout",
        //   action: () => this.$router.push("/checkout"),
        //   subcategory: [],
        //   condition: this.$store.getters.isAuthenticated, // Visible if checkout is not empty and selected page is not cart
        // },
        {
          id: 6,
          name: "Sell your Product on Market Place",

          page: "admin",
          action: () => (window.location.href = "/admin"),
          subcategory: [],
          condition: true, // Visible if cart is not empty and selected page is not cart
        },
        {
          id: 7,
          name: "Sign In",

          page: "signin",
          action: () => openLoginModal(),
          subcategory: [],
          condition: !this.$store.getters.isAuthenticated, // Visible if cart is not empty and selected page is not cart
        },
        {
          id: 6,
          name: "Logout",

          page: "logout",
          action: () => openLogoutModal(),
          subcategory: [],
          condition: this.$store.getters.isAuthenticated, // Visible if cart is not empty and selected page is not cart
        },
      ];
    },
    selectedCurrency() {
      return this.$store.getters?.getSelectedCurrency
        ? this.$store.getters.getSelectedCurrency.currency
        : "";
    },
    selectedCountry() {
      return this.$store.getters?.getSelectedCountry
        ? this.$store.getters.getSelectedCountry.name
        : "";
    },
    displayHeaderSlider() {
      const hideTopSliderForComp = ["product-details", "store-details", "cart"];
      return hideTopSliderForComp.indexOf(this.$route.name) !== -1
        ? false
        : true;
    },
    displayOtherTabs() {
      const hideTopSliderForComp = ["product-details", "store-details", "cart"];
      return hideTopSliderForComp.indexOf(this.$route.name) !== -1
        ? false
        : true;
    },
    displayCountry() {
      const hideTopCountryList = ["product-details", "store-details", "cart"];
      return hideTopCountryList.indexOf(this.$route.name) !== -1 ? false : true;
    },
  },
  data() {
    return {
      currency: "INR",
      cartArray: [],
      searchText: "",
      isHovered: false,
      allCategoryList: [],
      rootCategoryList: [],
      subCategoryList: [],
      isLoading: false,
      menuOpen: false,
      selectedOption: "",
      selectedTab: "Products",
      mobileCategorySubMenuOpen: false,
      options: [
        { id: "all_categories", label: "All Categories" },
        { id: "best_seller_products", label: "Best Seller Products" },
        { id: "top_10_offers", label: "Top 10 Offers" },
        { id: "new_arrivals", label: "New Arrivals" },
        { id: "phones_&_tablets", label: "Phones & Tablets" },
        { id: "electronics_&_digital", label: "Electronics & Digital" },
        { id: "fashion_&_clothings", label: "Fashion & Clothings" },
        { id: "jewelry_&_watches", label: "Jewelry & Watches" },
        { id: "health_&_beauty", label: "Health & Beauty" },
        { id: "sound_&_speakers", label: "Sound & Speakers" },
        { id: "tv_&_audio", label: "TV & Audio" },
        { id: "computers", label: "Computers" },
      ],
      isClear: false,
      baseCurrency: {},
      currencyList: [],
      countryList: [],
      showCurrencies: false,
    };
  },
  setup() {
    return {
      loginModal,
      registerModal,
      forgetPasswordModal,
      openLoginModal,
      logoutConformationModal,
      openLogoutModal,
      closeLogoutModal,
    };
  },
  mounted() {
    this.selectedOption = this.$route.params.id || "";
    this.searchText = this.$route.query.searchText || "";
    if (this.searchText) {
      this.isClear = true;
    }
    this.getCountryList();
    this.getCategoryList();
    this.getCurrencyList();
    // document.addEventListener('click', this.close);
    document.addEventListener("DOMContentLoaded", function () {
      // make it as accordion for smaller screens
      if (window.innerWidth < 992) {
        // close all inner dropdowns when parent is closed
        document
          .querySelectorAll(".navbar .dropdown")
          .forEach(function (everydropdown) {
            everydropdown.addEventListener("hidden.bs.dropdown", function () {
              // after dropdown is hidden, then find all submenus
              this.querySelectorAll(".submenu").forEach(function (
                everysubmenu
              ) {
                // hide every submenu as well
                everysubmenu.style.display = "none";
              });
            });
          });

        document.querySelectorAll(".dropdown-menu a").forEach((element) => {
          if(element){
            element.addEventListener("click", function (e) {
              console.log("click");
              // let nextEl = this.nextElementSibling;
              // if(nextEl && nextEl.classList.contains('submenu')) {
              //   // prevent opening link if link needs to open dropdown
              //   e.preventDefault();
              //   if(nextEl.style.display == 'block'){
              //     nextEl.style.display = 'none';
              //   } else {
              //     nextEl.style.display = 'block';
              //   }

              // }
            });
          }
        });
      }
      // end if innerWidth
    });
  },
  // created() {

  // },
  methods: {
    closeFourthLevelMenus(rcatInd) {
      const submenu = document.getElementById("submenu-" + rcatInd);
      const fourthLevelMenus = submenu.getElementsByClassName("submenu");
      for (let i = 0; i < fourthLevelMenus.length; i++) {
        fourthLevelMenus[i].style.display = "none";
      }
    },
    getCategoryList() {
      let url = `buyer/category?page=1&pageSize=500`;
      _commonService
        .get(url)
        .then((response) => {
          if (response.data.status == 1) {
            let records = response.data.results || [];
            this.rootCategoryList =
              records.filter((item) => item.is_root) || [];
            this.allCategoryList = records;
            this.subCategoryList =
              records.filter((item) => !item.is_root) || [];
            this.rootCategoryList.forEach((item) => {
              let subcat_level1 = this.subCategoryList.filter(
                (e) => e.parent_id._id == item._id
              );
              item.subcategory = subcat_level1;

              item.subcategory.forEach((ele) => {
                let subcat_level2 = this.subCategoryList.filter(
                  (c) => c.parent_id._id == ele._id
                );
                ele.subcategory = subcat_level2;

                ele.subcategory.forEach((ele_inner) => {
                  let subcat_level2 = this.subCategoryList.filter(
                    (s) => s.parent_id._id == ele_inner._id
                  );
                  ele_inner.subcategory = subcat_level2;

                  ele_inner.subcategory.forEach((ele_inner2) => {
                    let subcat_level3 = this.subCategoryList.filter(
                      (s) => s.parent_id._id == ele_inner2._id
                    );
                    ele_inner2.subcategory = subcat_level3;
                    ele_inner2.subcategory.forEach((ele_inner3) => {
                      let subcat_level4 = this.subCategoryList.filter(
                        (s) => s.parent_id._id == ele_inner3._id
                      );
                      ele_inner3.subcategory = subcat_level4;
                    });
                  });
                });
              });
            });
          } else {
            this.notyf.error(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error(error?.message);
        });
    },
    getCurrencyList() {
      let url = `buyer/currency`;

      _commonService
        .get(url)
        .then((response) => {
          if (response.data.status == 1) {
            this.currencyList = response.data.results || [];
            this.currencyList.forEach((currency) => {
              currency.label = `${currency.currency} - ${currency.name}`;
              currency.value = currency.currency;
            });

            const currency = this.currencyList.find(
              (currency) => currency.is_base_currency == true
            );
            this.baseCurrency = currency || {};
            let storedCurrency = localStorage.getItem("currency")
              ? JSON.parse(localStorage.getItem("currency"))
              : {};
            const isExits = this.currencyList.find(
              (currency) => currency._id == storedCurrency._id
            );
            if (storedCurrency && storedCurrency.currency && isExits) {
              // this.selectedCurrency=storedCurrency.currency;
              this.$store.dispatch("updateCurrency", storedCurrency);
            } else {
              // this.selectedCurrency=this.baseCurrency?.currency || '';
              localStorage.setItem(
                "currency",
                JSON.stringify(this.baseCurrency)
              );
              this.$store.dispatch("updateCurrency", this.baseCurrency);
            }
          } else {
            this.notyf.error(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error(error.message);
        });
    },
    getCountryList() {
      let url = `buyer/geography?page=1&pageSize=200&type=country&is_inactive=false`;
      _commonService
        .get(url)
        .then((response) => {
          if (response.data.status == 1) {
            this.countryList = response.data.results || [];
            this.countryList.forEach((item) => {
              item.label = `${item.name}`;
              item.value = item.name;
            });
            let storedCountry = localStorage.getItem("country")
              ? JSON.parse(localStorage.getItem("country"))
              : {};
            const isExits = this.countryList.find(
              (item) => item.name == storedCountry.name
            );
            if (storedCountry && storedCountry.name && isExits) {
              this.$store.dispatch("updateCountry", storedCountry);
            } else {
              if (this.countryList.length > 0) {
                localStorage.setItem(
                  "country",
                  JSON.stringify(this.countryList[0])
                );
                this.$store.dispatch("updateCountry", this.countryList[0]);
              } else {
                localStorage.setItem("country", "");
                this.$store.dispatch("updateCountry", {});
              }
            }
          } else {
            this.notyf.error(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error(error.message);
        });
    },
    openProductList(category) {
      this.$router.push(`/product-list/${category._id}`);
    },
    listAllProduct(){
      // this.$router.push(`/product-list`);
    },
    onCurrencyChange(event) {
      const isExits = this.currencyList.find(
        (currency) => currency.currency == event
      );
      if (isExits) {
        localStorage.setItem("currency", JSON.stringify(isExits));
        this.$store.dispatch("updateCurrency", isExits);
      } else {
        localStorage.setItem("currency", JSON.stringify(this.baseCurrency));
        this.$store.dispatch("updateCurrency", this.baseCurrency);
      }
    },
    onCountryChange(event) {
      const isExits = this.countryList.find((item) => item.name == event);
      if (isExits) {
        localStorage.setItem("country", JSON.stringify(isExits));
        this.$store.dispatch("updateCountry", isExits);
      } else {
        if (this.countryList.length > 0) {
          localStorage.setItem("country", JSON.stringify(this.countryList[0]));
          this.$store.dispatch("updateCountry", this.countryList[0]);
        } else {
          localStorage.setItem("country", "");
          this.$store.dispatch("updateCountry", {});
        }
      }
    },
    goToAdmin() {
      // let domain =window.location.host;
      let href = "/admin";
      if (!this.user) {
        window.open(href, "_blank");
        return;
      }
      if (this.user && !this.user.is_seller) {
        this.isLoading = true;
        let url = `seller/register`;
        _commonService
          .post(url, {})
          .then((response) => {
            if (response.data.status == 1) {
              this.$store.dispatch("updateSeller", true).then((data) => {
                window.open(href, "_blank");
              });
              // this.notyf.success(response.data.message)
              this.isLoading = false;
            } else {
              this.isLoading = false;
              this.notyf.error(response.data.message);
            }
          })
          .catch((error) => {
            console.log("error");
            this.isLoading = false;
            this.notyf.error(error.message);
          });
      } else {
        window.open(href, "_blank");
      }
    },
    close(e) {
      console.log(e);
      if (!this.$el.contains(e.target)) {
      }
    },
    onLogout() {
      this.$store.dispatch("logout").then(() => {
        this.notyf.success("Logout successfully");
      });
      closeLogoutModal();
      if (
        this.$route.fullPath.includes("/cart") ||
        this.$route.fullPath.includes("/checkout") ||
        this.$route.fullPath.includes("/profile") ||
        this.$route.fullPath.includes("/messages-list")
      ) {
        this.$router.push("/");
      }
    },
    handleOptionChange(option) {
      this.selectedOption = option;
      // this.handleSubmit();
    },
    handleSubmit() {
      this.$router.push({
        name: "product-list",
        params: {
          id: this.selectedOption,
        },
        query: {
          searchText: this.searchText,
        },
      });
    },
    onSearch() {
      this.isClear = true;
      this.$router.push({
        name: this.selectedTab == "Products" ? "product-list" : "sellers",
        params: {
          id: this.selectedOption,
        },
        query: {
          searchText: this.searchText,
        },
      });
    },
    clearSearch() {
      this.searchText = "";
      this.isClear = false;
      this.$router.push("/");
    },
    goToMessages() {
      this.$router.push("/messages-list/inbox/all");
    },

    onSelect(selectedItem) {
      this.onCurrencyChange(selectedItem);
    },
    onClear() {
      this.selected_currency = "";
    },
    onCountrySelect(selectedItem) {
      this.onCountryChange(selectedItem);
    },
    onCountryClear() {
      this.selected_currency = "";
      localStorage.setItem("country", "");
      this.$store.dispatch("updateCountry", {});
    },
  },
});
</script>
<template>
  <div>
    <login-view v-if="loginModal" :active="loginModal"></login-view>
    <register-view v-if="registerModal" :active="registerModal"></register-view>
    <forgot-password-view
      v-if="forgetPasswordModal"
      :active="forgetPasswordModal"
    ></forgot-password-view>
    <ContactExchangeModal
      v-if="contactInfoExchangeModal"
      :active="contactInfoExchangeModal"
    ></ContactExchangeModal>
    <header>
      <div class="header__area">
        <div class="header__top d-none d-sm-block header_top_bg">
          <!-- <div class="header__top d-none d-sm-block"> -->
          <div class="container">
            <div class="row align-items-center">
              <div class="col-xl-6 col-lg-4 col-md-12 d-none d-md-block">
                <div class="header__welcome">
                  <div class="logo">
                    <a class="navbar-brand" href="#" @click="$router.push('/')">
                      <img
                        class="pe-2"
                        src="@/assets/logo.png"
                        alt=""
                        height="30"
                      />
                      <img src="@/assets/sitename.png" alt="" height="30" />
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-xl-6 col-lg-8 col-md-12">
                <div
                  class="header__action d-flex justify-content-center justify-content-md-end"
                >
                  <ul>
                    <CountryComponent
                      v-if="displayCountry"
                      @onSelect="onCountryChange"
                      @onClear="onCountryClear"
                      :countryList="countryList"
                      :baseCountry="baseCountry"
                      :selectedCountry="selectedCountry"
                    ></CountryComponent>
                    <CurrencyComponent
                      @onSelect="onCurrencyChange"
                      :currencyList="currencyList"
                      :baseCurrency="baseCurrency"
                      :selectedCurrency="selectedCurrency"
                    ></CurrencyComponent>
                    <li
                      class="header_list"
                      v-if="$store.getters.isAuthenticated"
                    >
                      <div class="d-flex navigation-block pointer">
                        <a @click="$router.push('/profile/my-profile')">
                          <div class="icon-block">
                            <i id="icon" class="fal fa-user-circle"></i>
                          </div>
                          <div class="text-blockkk">
                            <div class="sub-label">
                              <span class="clickable"> My Account</span>
                            </div>
                          </div>
                        </a>
                      </div>
                    </li>

                    <li
                      class="header_list"
                      v-if="$store.getters.isAuthenticated"
                    >
                      <div
                        class="d-flex navigation-block pointer"
                        @click="goToMessages"
                      >
                        <div class="icon-block">
                          <i id="icon" class="far fa-comments-alt"></i>
                        </div>
                        <div class="text-blockkk">
                          <div class="sub-label">
                            <span class="clickable"> Messages</span>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li
                      class="header_list"
                      v-if="!$store.getters.isAuthenticated"
                    >
                      <div
                        class="d-flex login-block pointer"
                        @click="openLoginModal"
                      >
                        <div class="d-inline-flex icon-block">
                          <i id="icon" class="far fa-user"></i>
                        </div>
                        <div class="d-inline-flex text-blockk">
                          <div class="label">
                            <span class="clickable">Login</span>
                          </div>
                          <div class="sub-label">
                            <span class="clickable">Join for free</span>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li
                      class="header_list"
                      v-if="$store.getters.isAuthenticated"
                    >
                      <div
                        class="d-flex navigation-block pointer"
                        @click="openLogoutModal"
                      >
                        <div class="icon-block">
                          <i id="icon" class="far fa-sign-out"></i>
                        </div>
                        <div class="text-blockkk">
                          <div class="sub-label">
                            <span class="clickable">Logout</span>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="header__info p-md-0">
          <section class="slider__area">
            <div class="row align-items-center">
                <div class="col-xl-6 col-lg-6 col-md-5 d-md-none d-block">
                  <div class="header__welcome ps-5 ms-3">
                    <div class="logo mb-1">
                      <a class="navbar-brand" href="#" @click="$router.push('/')">
                        <img
                          class="pe-2"
                          src="@/assets/logo.png"
                          alt=""
                          height="30"
                        />
                        <img src="@/assets/sitename.png" alt="" height="30" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container px-md-0" style="position: relative">
              <!-- <div class="w-100 slider-height" v-if="displayHeaderSlider"> -->
                <MainSlider v-if="displayHeaderSlider" />
              <!-- </div> -->
              <div class="top-burger-icon">
                <div class="row align-items-center top">
                  <div class="d-flex align-items-center col">
                    <div class="side-menu d-inline d-sm-none mr-20">
                      <button
                        @click="menuOpen = true"
                        type="button"
                        class="side-menu-btn offcanvas-toggle-btn mt-2"
                      >
                        <i class="fas fa-bars"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="col-xl-12 col-lg-12 d-none d-sm-block"
                    v-if="displayHeaderSlider"
                  >
                    <div class="header-banner">
                      <div class="image-container">
                        <div class="banner-content banner-overlay">
                          <div class="row justify-content-center">
                            <div class="col-md-6 col-12 pt-3 action-tabs">
                              <ul class="nav nav-bar">
                                <li
                                  class="nav-item"
                                  @click="selectedTab = 'Products'"
                                >
                                  <a
                                    class="nav-link text-white"
                                    :class="
                                      selectedTab === 'Products'
                                        ? 'home-active'
                                        : ''
                                    "
                                    aria-current="page"
                                    href="#"
                                    >Products</a
                                  >
                                </li>
                                <li
                                  class="nav-item"
                                  @click="selectedTab = 'Manufactures'"
                                >
                                  <a
                                    class="nav-link text-white"
                                    :class="
                                      selectedTab === 'Manufactures'
                                        ? 'home-active'
                                        : ''
                                    "
                                    href="#"
                                    >Manufactures</a
                                  >
                                </li>
                              </ul>
                              <form @submit.prevent="onSearch">
                                <div class="banner-search-box">
                                  <div class="input-group px-4">
                                    <input
                                      class="form-control banner-input"
                                      type="text"
                                      :placeholder="
                                        selectedTab === 'Products'
                                          ? 'Search For Products...'
                                          : 'Search For Manufature...'
                                      "
                                      v-model="searchText"
                                      @keyup="onSearch()"
                                    />
                                    <div
                                      class="input-group-append"
                                      v-if="isClear"
                                      @click="clearSearch()"
                                    >
                                      <div class="clear-searchblock">
                                        <span
                                          style="margin: 6px"
                                          class="material-icons"
                                          >close</span
                                        >
                                      </div>
                                    </div>
                                    <span class="search-icon"
                                      ><i class="far fa-search"></i
                                    ></span>
                                  </div>
                                </div>
                              </form>
                              <div class="row">
                                <div class="col-md-6 col-6">
                                  <div class="flash-message mb-2 ms-auto">
                                    <div class="icon">
                                      <span
                                        style="font-size: 30px"
                                        class="material-icons"
                                        >calendar_month</span
                                      >
                                    </div>
                                    <div class="text">
                                      Lowest Prices In 90 days
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6 col-6">
                                  <div class="flash-message mb-2 me-auto">
                                    <div class="icon">
                                      <span
                                        style="font-size: 30px"
                                        class="material-icons"
                                        >schedule</span
                                      >
                                    </div>
                                    <div class="text">
                                      On-time delivery Guarantee
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!-- <div class="container px-md-0">
            <div class="row align-items-center">
              <div class="d-flex align-items-center col">
                <div class="side-menu d-inline d-sm-none mr-20">
                  <button
                    @click="menuOpen = true"
                    type="button"
                    class="side-menu-btn offcanvas-toggle-btn mt-2"
                  >
                    <i class="fas fa-bars"></i>
                  </button>
                </div>
              </div>
              <div class="col-xl-12 col-lg-12 d-none d-sm-block">
                <div class="header-banner">
                  <div class="image-container">
                    <div class="banner-image" ></div>
                    <div class="banner-content banner-overlay">
                      <div class="row justify-content-center">
                        <div class="col-md-6 col-12 pt-3 action-tabs">
                          <ul class="nav nav-bar">
                            <li class="nav-item" @click="selectedTab='Products'">
                              <a class="nav-link text-white" :class="selectedTab==='Products' ? 'home-active':''" aria-current="page" href="#">Products</a>
                            </li>
                            <li class="nav-item" @click="selectedTab='Manufactures'">
                              <a class="nav-link text-white" :class="selectedTab==='Manufactures' ? 'home-active':''" href="#">Manufactures</a>
                            </li>
                          </ul>
                          <form @submit.prevent="onSearch">
                            <div class="banner-search-box">
                              <div class="input-group px-4">
                                <input
                                class="form-control banner-input"
                                type="text"
                                :placeholder="selectedTab==='Products' ?'Search For Products...': 'Search For Manufature...'"
                                v-model="searchText"
                                @keyup="onSearch()"
                                />
                                <div class="input-group-append" v-if="isClear"  @click="clearSearch()">
                                    <div class="clear-searchblock">
                                        <span style="margin: 6px;" class="material-icons">close</span>
                                    </div>
                                </div>
                                <span class="search-icon"><i class="far fa-search"></i></span>
                              </div>
                            </div>
                            
                          </form>
                          <div class="row">
                            <div class="col-md-6 col-6">
                              <div class="flash-message mb-2 ms-auto">
                                <div class="icon"> <span   style="font-size:30px;" class="material-icons" >calendar_month</span></div>
                                <div  class="text">Lowest Prices In 90 days</div>
                              </div>
                            </div>
                            <div class="col-md-6 col-6">
                              <div class="flash-message mb-2 me-auto">
                                <div class="icon"> <span   style="font-size:30px;" class="material-icons" >schedule</span></div>
                                  <div  class="text">On-time delivery Guarantee</div>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>

        <div
          class="header__bottom d-none d-sm-block container header__bottom-border last-header-block"
        >
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col">
                <div class="header__bottom-left d-flex align-items-center">
                  <div class="main-menu">
                    <nav id="mobile-menu-2">
                      <ul class="d-flex align-items-center py-2 menu-list">
                        <Dropdown
                          :categories="rootCategoryList"
                          :selectedPage="this.$store.getters.getSelectedPage"
                          :displayOtherTabs="displayOtherTabs"
                          @open="openProductList"
                          @all="listAllProduct"
                        />
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
              <div
                class="col-xl-3 col-lg-4 col-12 d-lg-flex justify-content-end w-full"
              >
                <form
                  class="d-inline-flex align-items-center py-2"
                  v-if="displayOtherTabs"
                >
                  <span
                    class="float-end text-white pointer"
                    @click="goToAdmin()"
                    >Sell your Product on Market Place</span
                  >
                </form>
                <div class="d-inline-flex mx-3 align-items-center">
                  <span class="float-end text-white pointer">Help</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <!-- offcanvas area start -->

    <div class="offcanvas__area" :class="menuOpen ? 'opened' : ''">
      <div class="offcanvas__wrapper">
        <div class="offcanvas__close">
          <button
            @click="menuOpen = false"
            class="offcanvas__close-btn"
            id="offcanvas__close-btn"
          >
            <i class="fal fa-times"></i>
          </button>
        </div>
        <div class="offcanvas__content">
          <div class="offcanvas__logo mb-10">
            <a class="navbar-brand" href="#" @click="$router.push('/')">
              <img src="@/assets/logo.png" alt="" height="30" />
              <img
                src="@/assets/sitename.png"
                alt=""
                class="mobile-logo"
                height="30"
              />
            </a>
          </div>
          <div class="action-tabs">
            <div class="d-flex">
              <ul class="nav nav-bar">
                <li
                  class="nav-item"
                  @click="selectedTab = 'Products'"
                  style="padding: 0px 4px !important"
                >
                  <a
                    class="nav-link text-dark"
                    style="font-size: 0.85rem"
                    :class="selectedTab === 'Products' ? 'active' : ''"
                    aria-current="page"
                    href="#"
                    >Products</a
                  >
                </li>
                <li
                  class="nav-item"
                  @click="selectedTab = 'Manufactures'"
                  style="padding: 0px 4px !important"
                >
                  <a
                    class="nav-link text-dark"
                    style="font-size: 0.85rem"
                    :class="selectedTab === 'Manufactures' ? 'active' : ''"
                    href="#"
                    >Manufactures</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="offcanvas__search mb-25">
            <form @submit.prevent="onSearch">
              <div class="banner-search-box">
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    :placeholder="
                      selectedTab === 'Products'
                        ? 'Search For Products...'
                        : 'Search For Manufature...'
                    "
                    v-model="searchText"
                    style="padding: 0; margin-right: 5px"
                  />
                  <div
                    class="input-group-append"
                    v-if="isClear"
                    @click="clearSearch()"
                  >
                    <div class="clear-searchblock ">
                      <span style="margin-top: -4px" class="material-icons"
                        >close</span
                      >
                    </div>
                  </div>
                  <span class="search-icon" @click="onSearch()"
                    ><i class="far fa-search"></i
                  ></span>
                </div>
              </div>
            </form>
          </div>
          <!-- <div class="mobile-menu-2 fix mean-container"></div> -->
          <div class="mobile-menu-2 fix mean-container" data-v-22a52e7c="">
            <div class="mean-bar">
              <a
                href="#nav"
                class="meanmenu-reveal"
                style="right: 0px; left: auto; display: inline"
                ><span
                  ><span><span></span></span></span
              ></a>
              <nav class="mean-nav">
                <ul class="d-flex align-items-center" style="display: none">
                  <!--  -->

                  <li
                    v-for="navItem in navItems"
                    :key="navItem.id"
                    v-show="navItem.condition"
                  >
                    <a
                      @click="navItem.action"
                      :class="{
                        'color-default nav-link':
                          this.$store.getters.getSelectedPage === navItem.page,
                        'nav-link':
                          this.$store.getters.getSelectedPage !== navItem.page,
                      }"
                    >
                      {{ navItem.name }}
                    </a>

                    <CategoryComponent
                      v-if="
                        navItem.subcategory.length && mobileCategorySubMenuOpen
                      "
                      :items="navItem.subcategory"
                      @item-clicked="openProductList"
                    />

                    <a
                      @click="
                        mobileCategorySubMenuOpen = !mobileCategorySubMenuOpen
                      "
                      v-if="navItem.subcategory.length"
                      class="mean-expand"
                      style="font-size: 18px"
                    >
                      <i
                        :style="
                          mobileCategorySubMenuOpen
                            ? 'transform: rotate(45deg);'
                            : ''
                        "
                        class="fal fa-plus"
                      ></i>
                    </a>

                    <!-- <CurrencyComponent v-if="navItem.page=='currencies'" @onSelect="onCurrencyChange" :currencyList="currencyList" :baseCurrency="baseCurrency" :selectedCurrency="selectedCurrency"></CurrencyComponent> -->
                    <div v-if="navItem.page == 'currencies'">
                      <div class="currency-box">
                        <!-- <label for="currency" class="cust-label">Select Currency</label> -->
                        <Multiselect
                          class="currency-dd"
                          :searchable="true"
                          @select="onSelect"
                          @clear="onClear"
                          v-model="selectedCurrency"
                          :options="currencyList"
                        />
                      </div>
                    </div>
                    <div v-if="navItem.page == 'country'">
                      <div class="currency-box">
                        <Multiselect
                          :searchable="true"
                          @select="onCountrySelect"
                          @clear="onCountryClear"
                          v-model="selectedCountry"
                          :options="countryList"
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div class="offcanvas__action"></div>
        </div>
      </div>
    </div>
    <!-- offcanvas area end -->
    <div
      @click="menuOpen = false"
      class="body-overlay"
      :class="menuOpen ? 'opened' : ''"
    ></div>
    <div
      class="modal-overlay"
      @click="closeLogoutModal"
      :class="{ show: logoutConformationModal }"
    ></div>

    <div
      class="modal fade"
      :class="logoutConformationModal ? 'show' : ''"
      :style="logoutConformationModal ? 'display: block !important;' : ''"
    >
      <div
        class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-base"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="inquiryAddLabel">Confirm</h5>
            <button
              type="button"
              ref="inquiryModalCloseBtn"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeLogoutModal()"
              id="inquiryModalCloseBtn"
            ></button>
          </div>
          <div class="modal-body text-start">
            <div class="row">
              <div class="col-md-12 pb-2">
                <p>Are you sure to logout?</p>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="closeLogoutModal()"
            >
              No
            </button>
            <button type="button" class="btn btn-default" @click="onLogout">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- offcanvas area end -->
  </div>
</template>
<style lang="scss" scoped>


.menu-list {
  // overflow-x: auto;
  width: fit-content;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-menu {
  width: max-content !important;
}
.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}
.next-arrow {
  float: right;
  margin-left: 5px;
}
.navbar-collapse ul li {
  cursor: pointer;
}

.clear-searchblock {
  height: 47px;
  background: #fff;
  padding: 5px 10px;
}
.header_top_bg {
  background-color: #effcff !important ;
  padding: 15px 0;
}

.currency-box .multiselect {
  width: 240px !important;
}
.header-banner {
  height: 300px;
  // background: linear-gradient(0deg, #d9d9d9, #d9d9d9);
  .image-container {
    padding: 0;
    margin-left: unset;
    position: relative;
    .banner-image {
      width: auto;
      height: 300px;
      position: relative;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      // background-image:url(@/assets/banner-ts.jpeg);
    }
    .banner-overlay {
      // display: block;
      background-color: rgba(0, 0, 0, 0.3);
      // position: absolute;
      height: 300px;
      width: 100%;
      // top: 0;
      // padding: 10px;
    }
  }
}

.action-tabs {
  ul.nav-bar {
    width: fit-content;
    margin: 15px auto;
    .nav-item {
      padding: 0 10px;
      .nav-link {
        // color: #ffffff;
        font-weight: 100;
        font-size: 1.1rem;
        letter-spacing: 0.5px;
        padding: 5px 15px 2px;
      }
      .nav-link.home-active {
        border-bottom: 3px solid #ffffff;
        font-weight: 600;
      }
      .nav-link.active {
        color: #d63547;
        border-bottom: 3px solid #d63547;
        font-weight: 600;
      }
    }
  }

  .banner-search-box {
    .banner-input {
      height: 47px;
      border-radius: 25px;
      border: none;
    }
    .search-icon {
      background: #fff;
      width: 45px;
      height: 47px;
      border-radius: 0 25px 25px 0;
      padding: 12px;
      font-size: 20px;
    }
  }

  .flash-message {
    display: flex;
    width: fit-content;
    padding: 3px 7px;
    border-radius: 8px;
    // margin: auto;
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    max-width: 200px;
    .icon {
      display: inline-flex;
      background: #d63547;
      color: #ffffff;
      border-radius: 5px;
      padding: 10px;
      height: 50px;
      width: 50px;
      margin: auto;
    }
    .text {
      display: inline-flex;
      padding: 5px 12px;
      vertical-align: bottom;
      font-weight: 600;
      // margin-top: 5px;
      font-size: 14px;
    }
  }
}
.last-header-block {
  background-color: #d13042;
  color: #ffffff;
}

input.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.top-burger-icon{
  top: 0 !important;
  position: absolute;
  width: -webkit-fill-available;
}
@media (max-width: 575px) {
  .top-burger-icon{
    top: -33px !important;
  }
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
