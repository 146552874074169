<script>
import { onMounted } from "vue";

import { defineComponent } from "vue";
import _commonService from "../services/common_service";
import { API_ENDPOINT, BASE_URL } from "../../environment";
import ProductListView from "./ProductListView.vue";
import HeaderBar from "./HeaderBar.vue";
import MainSlider from "./MainSlider.vue";
import moment from "moment";
import BlogListView from "./BlogListView.vue";
import Features from "./Features.vue";
// import MainScript from "./../assets/template-assets/js/main.js";
export default defineComponent({
  name: "HomeView",
  components: {
    Features,
    ProductListView,
    HeaderBar,
    MainSlider,
    BlogListView,
    
  },

  inject: ["notyf"],
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  watch:{
    user:{
      handler:function(val){
        if(val && val._id){
          // console.log('user',val);
          // 
        }
      },
      deep:true
    }
  },
  data() {
    return {
      subscriber_email:'',
      savingSubscriber:false,
      cartItems: [],
      moment: moment,
      isLoading: false,
      storeList: [],
      page: 1,
      pageSize: 30,
      sortBy: "_created",
      sortOrder: "DESC",
      baseUrl: BASE_URL,
      productsList: [],
      paginationObj: {},
      pageCategories: [
        { label: "Trade Services", value: "Trade Services" },
        { label: "Home Page News", value: "Home Page News" },
        { label: "News", value: "News" },
        { label: "Shopping", value: "Shopping" },
        { label: "Get to know us", value: "Get to know us" },
        { label: "Help", value: "Help" },
        { label: "Other", value: "Other" },
      ],
      allPagesList: [],
      tradeServicePages: [],
      newsPages: [],
      shoppingPages: [],
      getToKnowUsPages: [],
      authPages: [
        { page_name: "Sign In", route: "/login" },
        { page_name: "New Registration", route: "/register" },
      ],
      myAccountPages: [
        { page_name: "Forgot Password", route: "/forgot-password" },
      ],
      userPages: [
        //  {page_name:'My Account',route:'/my-account'},
        { page_name: "My Shopping Cart", route: "/cart" },
      ],
      helpPages: [],
      cartArray: [],
      allCategoryList: [],
      rootCategoryList: [],
      subCategoryList: [],
      levelOneSubCategoryList:[],
      levelTwoSubCategoryList:[],
      levelThreeSubCategoryList:[],
      productCategoryList:[],
      productSubCategoryList:[],
      productPieces:[],
      unitTypes:[
        {
          name:'Piece/Pieces',
          _id:'PCS',
        },
        {
          name:'Kilogram',
          _id:'KG',
        },
        {
          name:'Litre',
          _id:'L',
        }
      ],
      unitsList:[],                              
      quotationForm:{
        category:'',
        sub_category_level1:'',
        sub_category_level2:'',
        sub_category_level3:'',
        unit_type:'',
      },
      quotationsubmitting:false,
      showProductList:false,
      sectionsList: [],
      homeSectionOne: { pages: [] },
      homeSectionTwo: { pages: [] },
      homeSectionThree: { pages: [] },
      homeSectionFour: { pages: [] },
    };
  },
  async created() {
    this.$store.dispatch("updateSelectedPage",'home')
    // this.getStoreList();
    this.getPageSettings();
    this.getCategoryList();
    this.getUnitList();
    this.showProductList=false;
    let watcherCalled=false; 
    this.$watch(
        () => [this.currency, this.selectedCountry],
        (newValues, oldValues) => {
          // console.log('Computed properties changed:', newValues, oldValues);
          watcherCalled=true;
          setTimeout(() => {
            this.showProductList=true;
          }, 2000);
      });

    if(!watcherCalled){
      setTimeout(() => {
            this.showProductList=true;
          }, 2000);
    }
    this.myAccountPages = [...this.myAccountPages, ...this.authPages];
    if(this.user && this.user._id){
      this.cartArray = await this.getCartItems();
      this.$store.dispatch("updateCart", this.cartArray);
    }
  },
  methods: {
    goPrev() {
      this.$refs.newssplide.go("-1");
    },
    goNext() {
      this.$refs.newssplide.go("+1");
    },
    getCartItems() {
      return new Promise((resolve) => {
        const url = `buyer/cart`;
        _commonService
          .get(url)
          .then((response) => {
            if (response.data.status == 1) {
              let cartArray = response.data.results || [];
              this.cartItems = cartArray;
              resolve(cartArray);
            } else {
              resolve(cartArray);
              this.notyf.error(response.data.message);
            }
          })
          .catch((error) => {
            resolve([]);
            console.log(error);
            this.notyf.error(error.message);
          });
      });
    },
    goToAdmin() {
      // let domain =window.location.host;
      let href = "/admin";
      if (!this.user) {
        window.open(href, "_blank");
        return;
      }
      if (!this.user.is_seller) {
        this.isLoading = true;
        let url = `seller/register`;
        _commonService
          .post(url, {})
          .then((response) => {
            if (response.data.status == 1) {
              this.$store.dispatch("updateSeller", true).then((data) => {
                window.open(href, "_blank");
              });
              // this.notyf.success(response.data.message)
              this.isLoading = false;
            } else {
              this.isLoading = false;
              this.notyf.error(response.data.message);
            }
          })
          .catch((error) => {
            console.log("error");
            this.isLoading = false;
            this.notyf.error(error.message);
          });
      } else {
        window.open(href, "_blank");
      }
    },
    getStoreList() {
      const url = `buyer/stores?page=1&pageSize=100`;
      _commonService
        .get(url)
        .then((response) => {
          if (response.data.status == 1) {
            this.storeList = response.data.results || [];
          } else {
            this.notyf.error(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error(error.message);
        });
    },
    getUnitList(){
            let url = `buyer/settings?type=UNIT_LIST&page=1&pageSize=500&is_inactive=false`;
            _commonService
            .get(url)
            .then((response) => {
            if (response.data.status == 1) {
                    this.unitsList = response.data.results || [];
            } else {
                this.notyf.error(response.data.message);
            }
            })
            .catch((error) => {
            console.log(error);
                this.notyf.error(error.message);
            });
    },
    goToDetails(item) {
      this.$router.push("/store-details/" + item._id);
    },
    getProductList() {
      this.isLoading = true;
      const url = `buyer/products?page=${this.page}&pageSize=${this.pageSize}&sortBy=${this.sortBy}&sortOrder=${this.sortOrder}`;
      _commonService
        .get(url)
        .then((response) => {
          if (response.data.status == 1) {
            this.productsList = response.data.results || [];
            this.productsList.forEach((item) => {
              if (item.price_type == "Multi Level Pricing") {
                if (item.prices) {
                  let defaultone = item.prices
                    ? item.prices.filter((level) => level.min_quantity <= 1)
                    : [];
                  if (defaultone.length) {
                    let default_price = defaultone.find(
                      (level) => level.min_quantity <= 1
                    );
                    item.default_price = default_price ? default_price : {};
                  } else {
                    let default_price = item.prices[0];
                    item.default_price = default_price ? default_price : {};
                  }
                } else {
                  item.default_price = {};
                }
              }
            });
            this.paginationObj = response.data.pagination || { items: [] };
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.notyf.error(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.notyf.error(error.message);
        });
    },
    getPageSettings() {
      let url = `buyer/page-settings?page=1&pageSize=100&sortBy=section_name&sortOrder=DSC`;
      _commonService
        .get(url)
        .then((response) => {
          if (response.data.status == 1) {
            this.sectionsList = response.data.results || [];
            this.homeSectionOne = this.sectionsList.find(
              (item) => item._id == "home_section_1"
            ) ||{ pages:[]};    
            this.homeSectionTwo = this.sectionsList.find(
              (item) => item._id == "home_section_2"
            ) ||{ pages:[]};
            this.homeSectionThree = this.sectionsList.find(
              (item) => item._id == "home_section_3"
            ) ||{ pages:[]};            
            this.homeSectionFour = this.sectionsList.find(
              (item) => item._id == "home_section_4"
            ) ||{ pages:[]};            
            this.homeSectionOne.pages=[];
            this.homeSectionTwo.pages=[];
            this.homeSectionThree.pages=[];
            this.homeSectionFour.pages=[];
            this.getPagesList();
          } else {
            this.notyf.error(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error(error.message);
        });
    },
    getPagesList() {
      // this.isLoading = this.$loading.show();
      const url = `buyer/page?page=1&pageSize=500`;
      _commonService
        .get(url)
        .then((response) => {
          if (response.data.status == 1) {
            this.allPagesList = response.data.results || [];

            this.homeSectionOne.pages = this.allPagesList.filter(
              (item) =>
                item.categories && item.categories.indexOf("home_section_1") != -1
            );
            this.homeSectionTwo.pages = this.allPagesList.filter(
              (item) =>
                item.categories && item.categories.indexOf("home_section_2") != -1
            );
            this.homeSectionThree.pages = this.allPagesList.filter(
              (item) =>
                item.categories && item.categories.indexOf("home_section_3") != -1
            );
            this.homeSectionFour.pages = this.allPagesList.filter(
              (item) =>
                item.categories && item.categories.indexOf("home_section_4") != -1
            );

            // this.tradeServicePages = this.allPagesList.filter(
            //   (item) =>
            //     item.categories &&
            //     item.categories.indexOf("Trade Services") != -1
            // );
            // this.newsPages = this.allPagesList.filter(
            //   (item) =>
            //     item.categories &&
            //     item.categories.indexOf("Home Page News") != -1
            // );
            // this.shoppingPages = this.allPagesList.filter(
            //   (item) =>
            //     item.categories && item.categories.indexOf("Shopping") != -1
            // );
            // this.getToKnowUsPages = this.allPagesList.filter(
            //   (item) =>
            //     item.categories &&
            //     item.categories.indexOf("Get to know us") != -1
            // );
            // this.helpPages = this.allPagesList.filter(
            //   (item) => item.categories && item.categories.indexOf("Help") != -1
            // );
            // this.isLoading.hide();
          } else {
            // this.isLoading.hide();
            this.notyf.error(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          // this.isLoading.hide();
          this.notyf.error(error.message);
        });
    },
    goToPageDetails(page) {
      this.$router.push(`/page/${page._id}`);
    },
    setSelectedItem(item) {
      this.selectedDropdown = item;
    },
    getCategoryList() {
      let url = `buyer/category?page=1&pageSize=500`;
      _commonService
        .get(url)
        .then((response) => {
          if (response.data.status == 1) {
            let records = response.data.results || [];
            this.rootCategoryList = records.filter((item) => item.is_root) || [];
            this.allCategoryList = records;
            this.subCategoryList = records.filter((item) => !item.is_root) || [];
            // this.rootCategoryList.forEach((item) => {
            //   let subcat_level1 = this.subCategoryList.filter(
            //     (e) => e.parent_id._id == item._id
            //   );
            //   item.subcategory = subcat_level1;

            //   item.subcategory.forEach((ele) => {
            //     let subcat_level2 = this.subCategoryList.filter(
            //       (c) => c.parent_id._id == ele._id
            //     );
            //     ele.subcategory = subcat_level2;

            //     ele.subcategory.forEach((ele_inner) => {
            //       let subcat_level2 = this.subCategoryList.filter(
            //         (s) => s.parent_id._id == ele_inner._id
            //       );
            //       ele_inner.subcategory = subcat_level2;

            //       ele_inner.subcategory.forEach((ele_inner2) => {
            //         let subcat_level3 = this.subCategoryList.filter(
            //           (s) => s.parent_id._id == ele_inner2._id
            //         );
            //         ele_inner2.subcategory = subcat_level3;
            //       });
            //     });
            //   });
            // });
          } else {
            this.notyf.error(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error(error.message);
        });
    },
    onCategoryChange(event,level){
      const selectedId=event.target.value ||'';
      if(level=='root'){
        this.quotationForm.sub_category_level1='';
        this.quotationForm.sub_category_level2='';
        this.quotationForm.sub_category_level3='';
        this.levelOneSubCategoryList=[];
        this.levelTwoSubCategoryList=[];
        this.levelThreeSubCategoryList=[];
        this.levelOneSubCategoryList = this.subCategoryList.filter((e) => e.parent_id._id == selectedId);
      }
      else if(level=='sub_level1'){
        this.quotationForm.sub_category_level2='';
        this.quotationForm.sub_category_level3='';
        this.levelTwoSubCategoryList=[];
        this.levelThreeSubCategoryList=[];
        this.levelTwoSubCategoryList = this.subCategoryList.filter((e) => e.parent_id._id == selectedId);

      }
      else if(level=='sub_level2'){
        this.quotationForm.sub_category_level3='';
        this.levelThreeSubCategoryList=[];
        this.levelThreeSubCategoryList = this.subCategoryList.filter((e) => e.parent_id._id == selectedId);

      }      
    },
    openProductList(category) {
      this.$router.push(`/product-list/${category._id}`);
    },
    quatationRequest() {
      if(!this.quotationForm.name){
        this.notyf.error('Please enter product name');
        return;
      }
      if(!this.quotationForm.category){
        this.notyf.error('Please select category');
        return;
      }
      if(!this.quotationForm.quantity){
        this.notyf.error('Please enter quantity');
        return;
      }
      if(!this.quotationForm.unit_type){
        this.notyf.error('Please select unit');
        return;
      }
        // console.log(this.quotationForm,'quotationForm');
      
      let payload={
        name:this.quotationForm.name,
        category_ids:[this.quotationForm.category],
        quantity:this.quotationForm.quantity,
        unit_type:this.quotationForm.unit_type,
        product_details:{
          product_id:'',
          quantity:this.quotationForm.quantity,
          unit:this.quotationForm.unit_type,
          title:this.quotationForm.name,
          currency:this.currency,                   
        }
      }
      if(this.quotationForm.sub_category_level1){
        payload.category_ids.push(this.quotationForm.sub_category_level1);
      }
      if(this.quotationForm.sub_category_level2){
        payload.category_ids.push(this.quotationForm.sub_category_level2);
      }
      if(this.quotationForm.sub_category_level3){
        payload.category_ids.push(this.quotationForm.sub_category_level3);
      }

    
      if (this.user && this.user._id) {
        this.quotationsubmitting=true
        let url = `buyer/quotation-request`;
        _commonService
          .post(url, payload)
          .then((response) => {
            if (response.data.status == 1) {
              this.notyf.success(response.data.message)
              this.quotationsubmitting=false;
              this.resetQuotationForm()
            } else {
              this.quotationsubmitting=false;
              this.notyf.error(response.data.message);
            }
          })
          .catch((error) => {
            console.log("error");
            this.quotationsubmitting=false;
            this.notyf.error(error.message);
          });
      } else {
       this.notyf.error('Please login to continue');
      }
    },
    resetQuotationForm(){
      this.quotationForm={
        category:'',
        sub_category_level1:'',
        sub_category_level2:'',
        sub_category_level3:'',
        unit_type:'',
      }
    },
    saveSubscriber(){
      if(!this.subscriber_email){
        this.notyf.error('Please enter email');
        return;
      }
      let emailRegex= /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if(!emailRegex.test(this.subscriber_email)){
        this.notyf.error('Please enter valid email');
        return;
      }
      let payload={
        email:this.subscriber_email,
      }
      this.savingSubscriber=true;
      let url = `buyer/email-subscription`;
      _commonService
        .post(url, payload)
        .then((response) => {
          this.savingSubscriber=false;
          if (response.data.status == 1) {
            this.notyf.success(response.data.message)
            this.subscriber_email='';
          } else {
            this.notyf.error(response.data.message);
          }
        })
        .catch((error) => {
          this.savingSubscriber=false;
          console.log("error");
          this.notyf.error(error.message);
        });
    },

  },
  computed:{
    user() {
      return this.$store.state.user;
    },
    currency(){
      return this.$store.getters?.getSelectedCurrency ? this.$store.getters.getSelectedCurrency.currency : '';  
    },
    country(){
      return this.$store.getters?.getSelectedCountry?.name ? this.$store.getters?.getSelectedCountry?.name : '';  
    }
  }
});
</script>

<template>
  <div class="home app-page pb-0">
    <!-- <div class="container-fluid">
      <div class="row" id="header" >
        <HeaderBar
          :user="user"
          :selectedPage="'home'"
        ></HeaderBar>
      </div>
    </div> -->
    <div class="home-contain">
      <!-- slider area satrt -->
      <!-- <section
        class="slider__area"
        data-background="@/assets/template-assets/img/slider/slider-bg-1.jpg"
      >
        <div class="container">
          <div class="w-100">
            <MainSlider />
          </div>
        </div>
      </section> -->
      <!-- slider area end -->
      <!-- <section class="features__area grey-bg-2">
        <div class="container">
          <Features />
        </div>
      </section> -->

      <div class="container-fluid mt-3">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="bg-white rounded-4 p-3 mb-3">
                <ProductListView
                  v-if="showProductList"
                  @callCartItems="initiateCart"
                  :type="'new-arrivals'"
                  :storeList="storeList"
                  :user="user"
                ></ProductListView>
                <BaseProductsView v-else :recordsFetching="!showProductList" :type="'new-arrivals'"/>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="bg-white rounded-4 p-3 mb-3">
                <ProductListView
                  v-if="showProductList"
                  @callCartItems="initiateCart"
                  :type="'trending-items'"
                  :storeList="storeList"
                  :user="user"
                ></ProductListView>
                <BaseProductsView v-else :recordsFetching="!showProductList" :type="'trending-items'" />
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="bg-white rounded-4 p-3 mb-3">
                <ProductListView
                  v-if="showProductList"
                  @callCartItems="initiateCart"
                  :type="'gifts'"
                  :storeList="storeList"
                  :user="user"
                ></ProductListView>
                <BaseProductsView v-else :recordsFetching="!showProductList" :type="'gifts'" />
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="bg-white rounded-4 p-3 mb-3">
                <ProductListView
                  v-if="showProductList"
                  @callCartItems="initiateCart"
                  :type="'sale-items'"
                  :storeList="storeList"
                  :user="user"
                ></ProductListView>
                <BaseProductsView v-else :recordsFetching="!showProductList" :type="'sale-items'"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid my-3 pb-3">
        <div class="container quotation-block p-5 bg-white">
          <div class="row justify-content-center items-align bg-white">
            <div class="col-md-6 d-none d-md-flex">
              <div class="img-holder d-none d-md-block">
                <img src="@/assets/quotation-img.png" alt="banner-img" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="row justify-content-center">
                <div class="col-md-10 col-lg-9  col-sm-12 mb-4 lable-holder">
                  <h3><b>Request for Quotation</b></h3>
                  <p >Single request Multiple Quotes from multiple suppliers</p>
                </div>
                <div class="col-lg-9 col-md-10 col-sm-12">
                  <form @submit.prevent="quatationRequest">
                    <div class="form-group mb-3">
                      <select name="category" id="category" @change="onCategoryChange($event,'root')" placeholder="Product category" class="form-select quot-input" v-model="quotationForm.category">
                        <option value="">Product category</option>
                        <option :value="category._id" v-for="(category,index) in rootCategoryList" :key="index">{{ category.name }}</option>
                      </select>
                      
                    </div>
                    <div class="form-group mb-3">
                      <select name="subcategory" id="subcategory" @change="onCategoryChange($event,'sub_level1')" placeholder="Product sub category" class="form-select quot-input" v-model="quotationForm.sub_category_level1">
                        <option value="">Product sub category</option>
                        <option :value="subcategory._id" v-for="(subcategory,index) in levelOneSubCategoryList" :key="index">{{ subcategory.name }}</option>
                      </select>
                    </div>
                    <div class="form-group mb-3" v-if="levelTwoSubCategoryList.length">
                      <select name="subcategory" id="subcategory" @change="onCategoryChange($event,'sub_level2')" placeholder="Product sub category" class="form-select quot-input" v-model="quotationForm.sub_category_level2">
                        <option value="">Product sub category</option>
                        <option :value="subcategory._id" v-for="(subcategory,index) in levelTwoSubCategoryList" :key="index">{{subcategory.name}}</option>
                      </select>
                    </div>
                    <div class="form-group mb-3" v-if="levelThreeSubCategoryList.length">
                      <select name="subcategory" id="subcategory"  placeholder="Product sub category" class="form-select quot-input" v-model="quotationForm.sub_category_level3">
                        <option value="">Product sub category</option>
                        <option :value="subcategory._id" v-for="(subcategory,index) in levelThreeSubCategoryList" :key="index">{{subcategory.name}}</option>
                      </select>
                    </div>
                    <div class="form-group mb-3">
                      <input type="text" class="form-control quot-input" placeholder="Enter Product Name" v-model="quotationForm.name">
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form-group d-inline-block mb-3 me-3 w-100">
                          <input type="text" class="form-control quot-input" placeholder="Quantity" v-model="quotationForm.quantity">
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group d-inline-block w-full mb-3  w-100">
                          <select name="piece" id="piece" class="form-select quot-input" v-model="quotationForm.unit_type">
                            <option value="">Select Unit</option>
                            <!-- <option :value="unit._id" v-for="(unit,index) in unitTypes" :key="index">{{ unit.name }}</option> -->
                            <option :value="unit?.label" v-for="(unit,index) in unitsList" :key="index">{{ unit?.label }} - {{ unit?.name }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                   
                   
                    <div class="row">
                      <div class="col-12 mx-auto">
                        <BaseButton type="submit" class="btn-default cust-btn w-100" :disabled="quotationsubmitting" >
                          <span class="flex justify-center items-center"> 
                            Request for Quotation
                            <svg
                              v-if="quotationsubmitting"
                              class="mini-loader ml-2"
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z"
                              />
                            </svg>
                          </span>
                        </BaseButton>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid my-3 pb-2" v-if="homeSectionOne?.is_enabled">
        <div class="container px-0">
          <div class="row" >
              <div class="col-md-12 app-info-bar">
                <div class="hero_image"
                :style="{
                  'background-image':
                    'url(&quot;' + homeSectionOne?.banner_image?.url + '&quot;)',
                }"
                > </div>
                  <div class="app-info p-md-5">
                      <h5 class="title-bar" v-if="homeSectionOne?.title">{{homeSectionOne?.title}}</h5>
                      <p class="sub-description">{{ homeSectionOne?.description }}</p>
                      <div class="text-center" v-if="homeSectionOne.pages.length">
                        <RouterLink :to="{ name: 'page', params: {id:homeSectionOne.pages[0]._id }}">
                          <a class="read-more text-white"  >Read our story in full</a>
                        </RouterLink>
                      </div>
                  </div>
              </div>
            </div>
        </div>
      </div>

      
      <blog-list-view
        v-if="homeSectionTwo.is_enabled && homeSectionTwo.pages.length"
        :title="homeSectionTwo.title"
        :description="homeSectionTwo.description"
        :list="homeSectionTwo.pages"
        :section="homeSectionTwo"
      />
      <blog-list-view
        v-if="homeSectionThree.is_enabled && homeSectionThree.pages.length"
        :title="homeSectionThree.title"
        :description="homeSectionThree.description"
        :list="homeSectionThree.pages"
        :section="homeSectionThree"
      />
      <blog-list-view
        v-if="homeSectionFour.is_enabled && homeSectionFour.pages.length"
        :title="homeSectionFour.title"
        :description="homeSectionFour.description"
        :list="homeSectionFour.pages"
        :section="homeSectionFour"
      />

      <div class="container-fluid py-4" >
        <div class="container">
          <div class="newLatterBack">
            <div class="top-circle"></div>
              <div class="row">
                  <div class="col-lg-7 col-xl-7 col-md-12 col-sm-12 mb-1" style="z-index: 5;">
                      <div class="news-letter">
                          <div class="news-icon">
                              <h4>Trade Alert - Delivering the latest product trends and industry new straight to your inbox </h4>
                              <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                  labore et dolore magna aliqua.</p> -->
                          </div>
                      </div>
                      <form class="form-inline cs-newsform mt-3">
                          <div class="form-group input-field">
                              <input type="text" class="form-control" id="newsiconemail" placeholder="Enter your email here"
                                  aria-label="Enter email address" v-model="subscriber_email">
                          </div>
                          <button type="button" class="cs-submit" id="newsiconbtn" @click="saveSubscriber()" :disabled="savingSubscriber">
                              Subscribe
                              <svg
                              v-if="savingSubscriber"
                              class="mini-loader ms-2"
                              viewBox="0 0 16 16"
                              >
                                <path
                                  d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z"
                                />
                              </svg>
                          </button>
                      </form>
                  </div>
                  <div class="col-lg-5 col-xl-5 col-md-7 col-sm-9">
                      <div class="rocket-image"></div>
                  </div>
              </div>
            <div class="bottom-circle"></div>
          </div>
        </div>
      </div>
    </div>

     
    <!-- subscribe area start -->
    <!-- <section class="subscribe__area pt-35 pb-30">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-xl-6 col-lg-6">
            <div class="subscribe__content d-sm-flex align-items-center">
              <div class="subscribe__icon mr-25">
                <img
                  src="@/assets/template-assets/img/icon/icon_email.png"
                  alt=""
                />
              </div>
              <div class="subscribe__text">
                <h4>Sign up to Newsletter</h4>
                <p>
                  Get email updates about our latest shop...and receive
                  <span>$30 Coupon For First Shopping</span>
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6">
            <div class="subscribe__form f-right">
              <form action="#">
                <input
                  type="email"
                  placeholder="Enter your email here..."
                />
                <button class="t-y-btn t-y-btn-sub">subscribe</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- subscribe area end -->

    
    <!-- back to top btn area start -->
    <section class="back-btn-top">
      <div class="container-fluid p-0">
        <div class="row gx-0">
          <div class="col-xl-12">
            <div class="back-to-top-btn text-center">
              <a href="#header">back to top</a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- back to top btn area end -->
  </div>
</template>
<style lang="scss">
.mini-loader{
  width: 1rem;
    height: 1rem;
    fill: currentColor;
    animation: spin 1s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.home-contain {
  padding-top: 10px;
  background: #f5f5f5;
}
.service-card {
  height: auto;
  // overflow-y: hidden;
  border: none;
  box-shadow: unset;
  // border: 1px solid lightgrey;
  // padding: 15px;
  // border-radius: 15px;
  .slide1_img {
    cursor: pointer;
    width: 100%;
    height: 200px;
    width: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px;
  }
}
.no-image {
  height: 200px;
  display: flex;
  background-color: #e5e5e5;
  width: 200px;
  border-radius: 8px;
}
.service_img {
  cursor: pointer;
  width: 100%;
  height: 180px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  // border-radius: 8px;
}
.service-card-content {
  height: 70px;
  text-align: left;
}
.news-no-image {
  height: 180px;
  display: flex;
  background-color: #c1c6cb;
  // width: 200px;
  // border-radius: 8px;
}
.content-block {
  position: relative;
  .details {
    position: absolute;
    bottom: 10px;
    font-size: 12px;
    cursor: pointer;
    color: dodgerblue;
  }
}
.hero_image {
  width: auto;
  height: 270px; //425px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #434343;
  // background-image: url(@/assets/home-banner.png);
}
.images-block {
  margin: 0;
  padding-top: 0;
  .img-container {
    padding: 0;
    margin-left: unset;
    display: flex;
  }
  .name-block {
    margin: auto 0;
  }
}
.app-info {
  //  display: block;
  //   background-color: rgba(0, 0, 0, 0.6);
  //   height:200px;
  position: absolute;
  top: 35px;
  text-align: center;
  .title-bar {
    position: relative;
    padding: 0;
    font-size: 20px;
    font-weight: 600;
    color: #fff;

  }
  .sub-description {
    position: relative;
    padding: 0 5px;
    font-size: 13px;
    color: #fff;
    letter-spacing: 0.4px;
  }
}

.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  height: 22px;
}
.app-info-bar {
  position: relative;
  // background: linear-gradient(0deg, #d9d9d9, #d9d9d9);
}

.quotation-block{
  .lable-holder{
    h3{
      font-size: 27px;
    }
    p{
      line-height: 18px;
      font-size: 18px;
    }
  }
  .continer-body{
      height: calc(100vh - 100px);
      display: flex;
      justify-content: center;
  }
  .quot-input{
    height: 50px !important;
    border: 1px solid #8b8b8b !important;
    color: #444444;
  }
  .items-align{
        align-items: center;
  }

  .img-holder{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: max-content;
  }

  .img-holder img{
      height: 450px;
      margin: auto;
      padding: 25px;
  }

  .cust-btn{
    padding: 12px;
    border-radius: 12px;
}


}
.newLatterBack {
    background: linear-gradient(90deg, rgba(96,3,15,1) 0%, rgba(203,28,47,1) 50%);
    width: 100%;
    padding: 60px;
    position: relative;
    border-radius: 25px;

    .rocket-image{
      // background-image: url(@/assets/rocket.png);
      // cursor: pointer;
      // width: 100%;
      // height: 100px;
      // background-position: center;
      // background-repeat: no-repeat;
      // background-size: contain;
    }

    .top-circle{
      height: 220px;
      width: 250px;
      position: absolute;
      background: rgb(245,243,243,1);
      opacity: 0.15;
      border-radius: 127px;
      top: -32px;
      left: -78px;
    }
    .bottom-circle{
      height: 120px;
      width: 276px;
      position: absolute;
      background: rgb(122, 3, 16);
      opacity: 0.15;
      border-radius: 127px 0 25px;
      bottom: 1px;
      right: 3px;
    }
}
.news-letter{
    position: relative;
 }
 .news-image{
    img{
        width: 100%;
        height: auto;
        min-height: 165px;
    }
}
.text-block{
    h4{
        font-weight: 700;
    }
    display: block;
    position: absolute;
    top: 50px;
    margin: auto;
    width: -webkit-fill-available;
    color: #fff;
}


.news-icon img {
    float: left;
    display: inline-block;
    padding-right: 24px;
    width: 90px;
}
.news-icon h4 {
    color: white;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.3px;
    margin-bottom: 18px;
}
.news-icon p {
    color: white;
    font-size: 15px;
    // font-family: Oswald, sans-serif;
}

.input-field{
    // width: -webkit-fill-available;
    display: inline-block;
    position: relative;
}
.cs-submit {
    border: none;
    cursor: pointer;
    background: white;
    color: #000;
    font-size: 14px;
    padding: 12px 15px;
    border-radius: 8px;  
    position: absolute;
    // right: 0;
    margin-left: 10px;
}
.cs-submit:hover{
  font-size: 15px;
}
.cs-newsform input[type="text"] {
  background-color: #890012;
    /* min-width: 200px; */
    width: 400px;
    height: 48px;
    border-radius: 8px;
    font-size: 15px;
    color: #fff;
    box-shadow: none;
    border: 1px solid #eaedff;
}
.cs-newsform input[type="text"]::-webkit-input-placeholder {
  color: #fff;
  opacity:1;
}

@media (max-width: 768px){
  .quotation-block .img-holder img {
    height: 345px;
  }

  .lable-holder{
    h3{
      font-size: 19px !important;
    }
    p{
      line-height: 18px !important;
      font-size: 15px !important;
    }
  }
  .cs-newsform .form-group.input-field{
      display: block;
      .form-control{
        margin-bottom: 8px !important;
      }
    }
    .cs-newsform input[type="text"]{
      width:-webkit-fill-available; //240px;
    }
    .cs-submit{
      position: unset;
      padding: 10px 12px;
      float: right;
    }
  
}

</style>
