import { ref } from 'vue'

export const loginModal = ref(false)
export const registerModal = ref(false)
export const forgetPasswordModal = ref(false)
export const logoutConformationModal = ref(false)
export const inquiryModal = ref(false)
export const viewImageModal = ref(false)
export const paymentModal = ref(false)
export const cancelModal = ref(false)
export const contactInfoExchangeModal = ref(false)

export function openCancelModal() {
	cancelModal.value = true
}

export function closeCancelModal() {
	cancelModal.value = false
}

export function openContactExchangeModal() {
	contactInfoExchangeModal.value = true
}
export function closeContactExchangeModal() {
	contactInfoExchangeModal.value = false
}
export function openPaymentModal() {
	paymentModal.value = true
}
export function closePaymentModal() {
	paymentModal.value = false
}
export function openInquiryModal() {
	inquiryModal.value = true
}
export function closeInquiryModal() {
	inquiryModal.value = false
}
export function openImageModal() {
	viewImageModal.value = true
}
export function closeImageModal() {
	viewImageModal.value = false
}

export function openLogoutModal() {

	logoutConformationModal.value = true
}
export function closeLogoutModal() {

	logoutConformationModal.value = false
}

export function openLoginModal() {
	registerModal.value = false
	forgetPasswordModal.value = false

	loginModal.value = true
}

export function openRegisterModal() {
	loginModal.value = false
	forgetPasswordModal.value = false

	registerModal.value = true
}

export function openForgetPasswordModal() {
	registerModal.value = false
	loginModal.value = false

	forgetPasswordModal.value = true
}

export function closeLoginModal() {
	loginModal.value = false
}

export function closeRegisterModal() {
	registerModal.value = false
}

export function closeForgetPasswordModal() {
	forgetPasswordModal.value = false
}
