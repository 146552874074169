<template>
  <div
    v-if="$store.getters.isAuthenticated"
    class="cart__mini-wrapper d-none d-md-flex f-right p-relative"
  >
    <a
      @click="isCartDropdown = !isCartDropdown"
      class="cart__toggle"
    >
      <span class="cart__total-item">{{
        $store.getters.getCartArrayLength
      }}</span>
    </a>

    <span class="cart__content">
      <span class="cart__my">My Cart:</span>
      <span class="cart__total-price"
        >{{ currency }} {{ $store.getters.getTotalCartPrice }} </span
      >
    </span>
    <CartDropdown
      :active="isCartDropdown"
      @cancel="isCartDropdown = false"
    />
  </div>
</template>

<script>
import CartDropdown from "./CartDropdown.vue";
export default {
  components: {
    CartDropdown,
  },
  data() {
    return {
      currency: "INR",
      isCartDropdown: false,
    };
  },
};
</script>
