<template>
  <div>
    <div class="button-group form-check form-check-inline">
      <component
        v-for="opts in options"
        :key="opts.value"
        :is="vertical ? 'div' : 'span'"
        :style="vertical ? '' : 'margin-right: 20px;'"
      >
        <BaseRadio
          :name="name"
          :label="opts.label"
          :modelValue="modelValue"
          :value="opts.value"
          @update:modelValue="$emit('update:modelValue', $event)"
        />
      </component>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    vertical: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: [String, Number],
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  }
}
</script>
<style scoped>
.button-group{
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
}
</style>