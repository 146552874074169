import { Notyf } from "notyf";
const themeColors = {
  primary: "#ae7886",
  primaryMedium: "#b4e4ce",
  primaryLight: "#f7fcfa",
  secondary: "#ff227d",
  accent: "#797bf2",
  accentMedium: "#d4b3ff",
  accentLight: "#b8ccff",
  success: "#7FB77E",
  info: "#F6F6F6",
  warning: "#C21010",
  danger: "#C21010",
  purple: "pruple",
  blue: "blue",
  green: "green",
  yellow: "yellow",
  orange: "orange",
  lightText: "#a2a5b9",
  fadeGrey: "#ededed",
};

const notyf = new Notyf({
  duration: 2000,
  position: {
    x: "right",
    y: "bottom",
  },

  types: [
    {
      type: "warning",
      background: themeColors.warning,
      icon: {
        className: "fas fa-hand-paper",
        tagName: "i",
        text: "",
      },
    },
    {
      type: "info",
      background: themeColors.info,
      icon: {
        className: "fas fa-info-circle",
        tagName: "i",
        text: "",
      },
    },
    {
      type: "primary",
      background: themeColors.primary,
      icon: {
        className: "fas fa-car-crash",
        tagName: "i",
        text: "",
      },
    },
    {
      type: "accent",
      background: themeColors.accent,
      icon: {
        className: "fas fa-car-crash",
        tagName: "i",
        text: "",
      },
    },
    {
      type: "purple",
      background: themeColors.purple,
      icon: {
        className: "fas fa-check",
        tagName: "i",
        text: "",
      },
    },
    {
      type: "blue",
      background: themeColors.blue,
      icon: {
        className: "fas fa-check",
        tagName: "i",
        text: "",
      },
    },
    {
      type: "green",
      background: themeColors.green,
      icon: {
        className: "fas fa-check",
        tagName: "i",
        text: "",
      },
    },
    {
      type: "orange",
      background: themeColors.orange,
      icon: {
        className: "fas fa-check",
        tagName: "i",
        text: "",
      },
    },
  ],
});

export default function useNotyf() {
  return {
    dismiss: (notification: any) => {
      notyf.dismiss(notification);
    },
    dismissAll: () => {
      notyf.dismissAll();
    },
    success: (payload: any) => {
      return notyf.success(payload);
    },
    error: (payload: any) => {
      return notyf.error(payload);
    },
    info: (payload: any) => {
      const options: any = {
        type: "info",
      };

      if (typeof payload === "string") {
        options.message = payload;
      } else {
        Object.assign(options, payload);
      }

      return notyf.open(options);
    },
    warning: (payload: any) => {
      const options: any = {
        type: "warning",
      };

      if (typeof payload === "string") {
        options.message = payload;
      } else {
        Object.assign(options, payload);
      }

      return notyf.open(options);
    },
    primary: (payload: any) => {
      const options: any = {
        type: "primary",
      };

      if (typeof payload === "string") {
        options.message = payload;
      } else {
        Object.assign(options, payload);
      }

      return notyf.open(options);
    },
    purple: (payload: any) => {
      const options: any = {
        type: "purple",
      };

      if (typeof payload === "string") {
        options.message = payload;
      } else {
        Object.assign(options, payload);
      }

      return notyf.open(options);
    },
    blue: (payload: any) => {
      const options: any = {
        type: "blue",
      };

      if (typeof payload === "string") {
        options.message = payload;
      } else {
        Object.assign(options, payload);
      }

      return notyf.open(options);
    },
    green: (payload: any) => {
      const options: any = {
        type: "green",
      };

      if (typeof payload === "string") {
        options.message = payload;
      } else {
        Object.assign(options, payload);
      }

      return notyf.open(options);
    },
    orange: (payload: any) => {
      const options: any = {
        type: "orange",
      };

      if (typeof payload === "string") {
        options.message = payload;
      } else {
        Object.assign(options, payload);
      }

      return notyf.open(options);
    },
  };
}
