<script>
import _commonService from "./services/common_service";
import HeaderBar from "./views/HeaderBar.vue";

import {
  closeLoginModal,
  openRegisterModal,
  openForgetPasswordModal,
  openLoginModal,
} from "./state/authState";
export default {
  inject: ["notyf"],
  components: {
    HeaderBar,
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      isLoading: false,
      allPagesList: [],
      tradeServicePages: [],
      newsPages: [],
      footerSectionOne: {
        pages:[],
      },
      footerSectionTwo: { pages:[],},
      footerSectionThree: { pages:[],},
      authPages: [
        { page_name: "Sign In", action: () => openLoginModal() },
        { page_name: "New Registration", action: () => openRegisterModal() },
      ],
      myAccountPages: [
        {
          page_name: "Forgot Password",
          action: () => openForgetPasswordModal(),
        },
      ],
      userPages: [
        //  {page_name:'My Account',route:'/my-account'},
        { page_name: "My Shopping Cart", route: "/cart" },
      ],
      helpPages: [],
      contactUsPage: {},
      sectionsList: [],
    };
  },
  created() {
    this.getPageSettings();
  },
  setup() {
    return { closeLoginModal, openRegisterModal, openForgetPasswordModal };
  },
  methods: {
    getPageSettings() {
      let url = `buyer/page-settings?page=1&pageSize=100&sortBy=section_name&sortOrder=DSC`;
      _commonService
        .get(url)
        .then((response) => {
          if (response.data.status == 1) {
            this.sectionsList = response.data.results || [];
            this.footerSectionOne = this.sectionsList.find(
              (item) => item._id == "footer_section_1"
            ) ||{ pages:[]};    
            this.footerSectionTwo = this.sectionsList.find(
              (item) => item._id == "footer_section_2"
            ) ||{ pages:[]};
            this.footerSectionThree = this.sectionsList.find(
              (item) => item._id == "footer_section_3"
            ) ||{ pages:[]};            
            this.footerSectionOne.pages=[];
            this.footerSectionTwo.pages=[];
            this.footerSectionThree.pages=[];
            this.getPagesList();
          } else {
            this.notyf.error(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error(error.message);
        });
    },
    getPagesList() {
      this.isLoading = this.$loading.show();
      const url = `buyer/page?page=1&pageSize=500`;
      _commonService
        .get(url)
        .then((response) => {
          if (response.data.status == 1) {
            this.allPagesList = response.data.results || [];
         
            this.footerSectionOne.pages = this.allPagesList.filter(
              (item) =>
                item.categories && item.categories.indexOf("footer_section_1") != -1
            );
            this.footerSectionTwo.pages = this.allPagesList.filter(
              (item) =>
                item.categories &&
                item.categories.indexOf("footer_section_2") != -1
            );
            this.footerSectionThree.pages = this.allPagesList.filter(
              (item) =>
                item.categories &&
                item.categories.indexOf("footer_section_3") != -1
            );
            this.helpPages = this.allPagesList.filter(
              (item) => item.categories && item.categories.indexOf("footer_section_1") != -1
            );
            this.contactUsPage = this.helpPages.find(
              (item) => item.page_id == "contact_us"
            );
            this.isLoading.hide();
          } else {
            this.isLoading.hide();
            this.notyf.error(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading.hide();
          this.notyf.error(error.message);
        });
    },
    goToPageDetails(page) {
     
      if(page.page_id=='contact_us'){
        this.$router.push(`/contact/${page._id}`)
      }else{
        this.$router.push(`/page/${page._id}`);
      }
    },
    visitHelpPages(item){
      if(item.page_id=='contact_us'){
        this.$router.push(`/contact/${item._id}`)
      }else{
        this.$router.push(`${item.route}/${page._id}`)
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
};
</script>

<template>
  <!-- <nav class="navbar navbar-light bg-light app-header">
        <div class="container-fluid px-md-5">
          <a class="navbar-brand" href="#"  @click="$router.push('/')">
            <img src="./assets/logo.png" alt="" height="30" />
            <img src="./assets/sitename.png" alt="" height="30" />
          </a>
          
          <v-button class="float-end btn btn-default" @click="$router.push('/login')" v-if="!$store.getters.isAuthenticated">Login</v-button>
          <v-button class="float-end btn btn-default"  @click="$store.dispatch('logout')" v-if="$store.getters.isAuthenticated">Logout</v-button>
        </div>
  </nav> -->
  <div class="app-div">
    <main>
      <div class="container-fluid">
        <div class="row" id="header" >
          <HeaderBar :user="user" ></HeaderBar>
        </div>
      </div>
      <router-view />
    </main>
  </div>
  <!-- footer area start -->
  <footer>
    <div class="footer__area footer-bg-2 yellow-footer">
      <div class="footer__top container">
        <div class="container custom-container">
          <div class="row justify-content-center">
            <div class="col-md-6 col-lg-4 col-xl-3 col-12 my-2">
              <div class="footer__widget footer-section">
                <div class="footer__widget-title">
                  <div class="footer__logo">
                    <a class="navbar-brand" @click="$router.push('/')" >
                      <img src="@/assets/logo.png" alt="" height="27" />
                      <img src="@/assets/sitename.png" alt="" height="27"  class="ps-3"/>
                    </a>
                  </div>

                </div>
                <div class="footer__widget-content" v-if="footerSectionOne?.is_enabled && footerSectionOne?.pages && footerSectionOne?.pages.length" >
                      <div class="footer__link footer__link-4" style="padding-left: 65px;">
                        <ul>
                          <li
                            v-for="(item, index) in footerSectionOne.pages"
                            :key="index"
                            @click="goToPageDetails(item)"
                          >
                            <a>- {{ item.page_name }}</a>
                          </li>
                        </ul>
                      </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3 col-sm-4 col-6 my-2" v-if="footerSectionTwo?.is_enabled && footerSectionTwo?.pages && footerSectionTwo?.pages.length" >
              <div class="footer-section">
                <div class="footer__widget-title footer__widget-title-4"><h4>{{footerSectionTwo?.title ||'Get to know us'}}</h4></div>
                <div class="footer__widget-content">
                      <div class="footer__link footer__link-4">
                        <ul>
                          <li
                            v-for="(item, index) in footerSectionTwo.pages"
                            :key="index"
                            @click="goToPageDetails(item)"
                          >
                            <a>- {{ item.page_name }}</a>
                          </li>
                        </ul>
                      </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3 col-sm-4 col-6 my-2" v-if="footerSectionThree?.is_enabled">
              <div class="footer-section">
                <div class="footer__widget-title footer__widget-title-4"> <h4>{{footerSectionThree?.title || 'My Account'}}</h4></div>
                <div class="footer__widget-content">
                      <div class="footer__link footer__link-4">
                        <ul>
                          <li
                            v-for="(item, index) in myAccountPages"
                            :key="index"
                            @click="item.action"
                          >
                            <a>- {{ item.page_name }}</a>
                          </li>
                          <template v-if="$store.getters.isAuthenticated">
                            <li
                              v-for="(item, index) in userPages"
                              :key="index"
                              @click="$router.push(`${item.route}`)"
                            >
                              <a>- {{ item.page_name }}</a>
                            </li>
                          </template>
                          <template v-if="!$store.getters.isAuthenticated">
                            <li
                              v-for="(item, index) in authPages"
                              :key="index"
                              @click="item.action"
                            >
                              <a>- {{ item.page_name }}</a>
                            </li>
                          </template>
                          <template v-if="footerSectionThree?.pages && footerSectionThree?.pages.length">
                            <li
                              v-for="(item, index) in footerSectionThree.pages"
                              :key="index"
                              @click="goToPageDetails(item)"
                            >
                              <a>- {{ item.page_name }}</a>
                            </li>
                          </template>
                        </ul>
                      </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3 my-2" v-if="contactUsPage?.social_shares && contactUsPage.social_shares.length">
              <div class="footer-section">
                <div class="footer__widget-title footer__widget-title-4"><h4>Follow Us</h4></div>
                <div class="footer__widget-content social-block" >
                  <template v-for="(item,index) in contactUsPage.social_shares" :key="index">
                    <a :href="item?.value" target="blank">
                      <img v-if="item?.name.toLowerCase()=='twitter'" src="./assets/twitter.jpg" alt="twitter" />
                      <img v-if="item?.name.toLowerCase()=='instagram'" src="./assets/instagram.png" alt="instagram" />
                      <img v-if="item?.name.toLowerCase()=='facebook'" src="./assets/facebook.png" alt="facebook" />
                      <img v-if="item?.name.toLowerCase()=='linkedin'"  src="./assets/linkedin.png" alt="linkedin" />
                    </a>
                  </template>
                </div>
              </div>
            </div>
              <!-- <div class="col-md-3 col-sm-4 col-6 mb-2" v-if="shoppingPages.length">
              <div class="footer__widget-title footer__widget-title-4"><h4>Shopping</h4></div>
              <div class="footer__widget-content">
                  <div class="footer__link footer__link-4">
                    <ul>
                      <li
                        v-for="(item, index) in shoppingPages"
                        :key="index"
                        @click="goToPageDetails(item)"
                      >
                        <a>{{ item.page_name }}</a>
                      </li>
                    </ul>
                  </div>
              </div>
            </div> -->
                 <!-- <div class="col-md-2 col-sm-4 col-6 mb-2">
              <div class="footer__widget-title footer__widget-title-4">
                <h4>Help</h4>
              </div>
              <div class="footer__widget-content">
                      <div class="footer__link footer__link-4">
                        <ul>
                          
                          <li
                            v-for="(item, index) in helpPages"
                            :key="index"
                            @click="visitHelpPages(item)"
                            >
                            <a>{{ item.page_name }}</a>
                          </li>
                        </ul>
                      </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="footer__copyright py-3 footer__copyright-2 footer-bottom-bg">
        <div class="container custom-containe">
            <div class="footer__copyright-text footer__copyright-text-2">
                <p class="text-center">
                  Copyright © {{ currentYear }} Trade in Safe.
                </p>
            </div>
            
        </div>
      </div>
    </div>
  </footer>
  <!-- footer area end -->
</template>

<style lang="scss">
.multiselect.currency-dd .multiselect-clear {
        display: none !important;
}
.row {
  display: flex;
  // justify-content: space-between;
}

.social-icon {
  font-size: 30px;
  fill: white;
  margin: 0 18px;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-item {
  flex: 0 0 auto;
  width: calc(100% / var(--num-columns));
  /* Additional styles for each column */
}

.no-end-space {
  justify-content: flex-start;
}

:root {
  --primary: #4ade80;
  --primary-alt: #22c55e;
  --grey: #64748b;
  --dark: #1e293b;
  --dark-alt: #334155;
  --light: #f1f5f9;
  --sidebar-width: 220px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  width: 100%;
  overflow-x: hidden;
}

#mobile-menu {
  padding: 0;
}
.app-div {
  display: flex;
  // top: 58px;
  position: relative;
  width: 100%;
  min-height: calc(100vh - 105px);
  main {
    width: 100%;
    // flex: 1 1 0;
    // overflow: auto;
  }
}
.app-header {
  position: fixed !important;
  display: flex !important;
  flex-direction: column;
  width: 100%;
  z-index: 99;
}
.app-page {
  // padding-bottom: 2.5rem;
}
.show-ellipsis{
  display: block;
  max-height: calc(1.2em * 2); /* Change 1.2em based on your font size and line height */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}
.row.card-list > * {
  padding-right: 5px !important;
  padding-left: 5px !important;
}
/* textarea:focus,
  input[type="text"]:focus,
  input[type="password"]:focus,
  input[type="datetime"]:focus,
  input[type="datetime-local"]:focus,
  input[type="date"]:focus,
  input[type="month"]:focus,
  input[type="time"]:focus,
  input[type="week"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="url"]:focus,
  input[type="search"]:focus,
  input[type="tel"]:focus,
  input[type="color"]:focus,
  .uneditable-input:focus {   
    border-color: rgba(250, 62, 62, 0.8);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(250, 62, 62, 0.6);
    outline: 0 none;
  } */
/* header color #FEF3E8 */
/* button color #D63547 */
/* header color #D63547 */
/* sub heading colo #A6787E */
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}
nav.bg-light {
  background-color: #fef3e8 !important;
}
.box-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.12);
}
.btn-default {
  background-color: #d63547 !important;
  color: #ffffff !important;
}
.color-default {
  color: #d63547 !important;
}
.color-secondary {
  color: #a6787e !important;
}
nav a.router-link-exact-active {
  color: #42b983;
}
.pointer {
  cursor: pointer;
}
.router-link a {
  text-decoration: unset;
  color: #d63547 !important;
}
.cartbadge {
  font-size: 10px;
  left: 14px;
  top: 13px;
}
/* auth pages css login, registration,  */
.continer-body {
  height: calc(100vh - 150px); //100px
  display: flex;
  justify-content: center;
}
.items-align {
  align-items: center;
}
.forgot-password {
  margin-top: -16px;
  font-size: 15px;
  cursor: pointer;
}
.dummy_list{
  .product_price{
    display: flex;
    .price{
      background: #ebe9e9;
      width: 100px;
      border-radius: 5px;
      height: 17px;
      margin-bottom: 7px;
      margin-right: 5px;
      margin-top: 7px;
      margin-left: 3px;
    }
  }
  .product_name{
    background: #ebe9e9;
    width: 115px;
    border-radius: 5px;
    height: 16px;
    margin-bottom: 5px;
    margin-left: 3px;
  }
}
.card-bg{
    background-color: #f3f3f3;
    border-radius: 8px;
    height: 130px;//150px;
    padding: 5px;
}
@media (max-width: 425px){

.product__item{
  width: 125px;

  .card-bg{
    height: 142px;
  }

  .product_img{
    height: 129px;
  }
}
@media (max-width: 425px){
  .newLatterBack {
    padding: 25px;
    .top-circle{
      top: -60px;
      left: -120px;
    }
    .bottom-circle{
      height: 40px;
      width: 145px;
      border-radius: 127px 0 55px;
      bottom: 4px;
      right: 0px;
    }
    .cs-newsform .form-group.input-field{
      display: block;
      .form-control{
        margin-bottom: 8px !important;
      }
    }
    .cs-newsform input[type="text"]{
      width:-webkit-fill-available; //240px;
    }
    .cs-submit{
      position: unset;
      padding: 10px 12px;
      float: right;
    }
  }

  .product__item{
    width: 125px; //155

    .card-bg{
      height: 142px;
    }

    .product_img{
      height: 129px;
    }
  }
  
}

.no-image-found{
  height: 130px;
    img{
      height: 100%;
      width: auto;
    };
  }  
}
.img-holder {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: max-content;
}

.img-holder img {
  height: 600px;
  margin: auto;
  border-radius: 5px 50px;
}
.img-holder .banner-text {
  position: absolute;
  bottom: 100px;
  background: rgba(255, 255, 255, 0.15);
  color: #ffffff;
  width: 315px;
  padding: 15px;
  text-align: initial;
  left: 0;
  border-radius: 3px 25px;
  margin: 0 40px;
}

#sub-text {
  font-size: 13px;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1040;
  display: none;
}

.modal-overlay.show {
  display: block;
}

// Common Header Bar CSS
.navigation-block{
  flex-direction: column; 
  .icon-block{
      height: 35px;
      width: 35px;
      margin: auto;

      #icon{
        font-size: 25px;
        font-weight: 300 !important;
      }
  }

  .text-blockkk .sub-label{
    line-height: initial;
    font-size: 14px;
    font-weight: 600;
  }
}

.login-block{
  .icon-block{
    width: 45px;
    #icon{
      font-size: 35px;
      font-weight: 300 !important;
      line-height: 35px;
    }
  }
  .text-blockk{
  flex-direction: column;
  
  .label{
    line-height: initial;
    padding: 3px 0 2px;
    font-size: 10px;
    .span{
      font-size: 12px;
      color: #b9b8b8;
    }
  }

  .sub-label{
    line-height: initial;
    font-size: 14px;
    font-weight: 600;
  }

}
}
.multiselect  input.multiselect-search{
    padding-left: 0 !important;
}
/* product card css */
.business-card {
  height: auto;
  overflow-y: hidden;
  border: none;
  box-shadow: unset;
  border: 1px solid lightgrey;
  padding: 15px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.card-content {
  height: 52px;
  text-align: left;
}
.slide1_img {
  cursor: pointer;
  width: 100%;
  height: 150px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
}

.product_img {
  cursor: pointer;
  width: 100%;
  height: 119px; //140px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 8px;
  background-color: white;
}
.page-link {
  box-shadow: 0px 0px 2px #bbbbbb!important;
  background-color: #fff!important;
  border: 0!important;
  color: #a6787e !important;
}

ul.pagination {
  float: right!important;
}

.page-item.active .page-link {
  background-color: #d63547!important;
  border-color: #d63547!important;
  color: #fff !important;
  /* padding-left: 13px;
    padding-right: 13px; */
}
.is_disabled{
  opacity: 0.6;
}
.quantity-field{
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none !important; 
      -moz-appearance: none !important;
      appearance: none !important;
      margin: 0 !important; 
  }
}
.footer-section{
  background: #f7f7f7;
    padding: 25px 26px 30px;
    height: 100%;
}
/* ============ desktop view ============ */
@media all and (min-width: 992px) {
  .dropdown-menu li {
    position: relative;
  }
  .nav-item .submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -7px;
  }
  .nav-item .submenu-left {
    right: 100%;
    left: auto;
  }
  .dropdown-menu > li:hover {
    background-color: #f1f1f1;
  }
  .dropdown-menu > li:hover > .submenu {
    display: block;
  }
}
/* ============ desktop view .end// ============ */

/* ============ small devices ============ */
@media (max-width: 991px) {
  .dropdown-menu > li:hover > .submenu {
    display: block;
  }
  .dropdown-menu .dropdown-menu {
    margin-left: 0.7rem;
    margin-right: 0.7rem;
    margin-bottom: 0.5rem;
  }
}

.footer__widget-content.social-block img{
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin: 5px;
    cursor: pointer;
}
.product__slider .splide.product__grid .splide__track .splide__list{
        flex-flow: wrap !important;
}
.grid-page-view{
        min-height: calc(100vh - 500px);
}
.store-page-view{
  min-height: calc(100vh - 235px);
}
// STore header section CSS
.store__details{

  .store-image-container{
    width: 200px;
    // border: 1px solid #e5dfdf;
    padding: 12px;
    margin: 0 auto 10px;
  }
  .img-box img {
    width: 160px;
    height: 150px;
  }
  .store-image {
    width: 175px;
    height: 120px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: 1px solid #e5dfdf;
  }
  .inner-info {
    padding: 5px 0;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap
  }
  .info-block {
    display: inline-flex;
    align-items: center;
    text-decoration: none;

    transition: 0.2s ease-in-out;
    padding: 0.5rem 0rem;
    color: #159f2c!important;
    
  }

  .info-block .material-icons {
    font-size: 2rem;
    transition: 0.2s ease-in-out;
    margin-right: 12px;
  }
  .info-block .text {
    font-size: 14px;
    font-weight: 600;
    transition: 0.2s ease-in-out;
  }
}
</style>
